.compaines-creativeproducts-reviews {
    margin-top: 20px;
}

.companies-creativeproducts {
    margin-bottom: 20px;
}

.carouselflexs {
    padding: 10px;
}

.carouselflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: fadeIn 1s ease-in-out;
}

.companies-creativeproducts-slides{
    margin: 40px;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.carousels {
    width: 400px;
    height: 300px;
    border-radius: 20px;
    margin: 0 auto;
    border: 2px solid rgb(5, 38, 89);
    transform: scale(0.94);
}

.carousels:hover {
    transform: scale(1);
}

.students-Reviews1 {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    margin: 0 auto;
    padding: 10px;
    border: none;
}

.customers-reviews-h1 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    padding-top: 40px;
}

.carouselsimages {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.creativeproductsh1 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    line-height: 2rem;
}

.creativeproductsh1-spans-2 {
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.5rem;
}

@media (min-width: 320px) and (max-width: 767px) {
    .carousels,
    .Review2 {
        width: 280px;
        height: 200px;
        border-radius: 0px;
    }
    .customers-reviews-h1 {
        font-size: 24px;
        padding-bottom: 0px;
        padding-top: 20px;
    }
    .creativeproductsh1 {
        font-size: 24px;
    }
    .creativeproductsh1-spans-2 {
        font-size: 1.2rem;
        padding: 0px 10px 0px 10px;
    }
}

@media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .compaines-creativeproducts-reviews{
        padding-bottom: 60px;
    }
    .Review1 {
        width: 600px;
        height: 400px;
    }
    .carousels {
        width: 300px;
        height: 200px;
    }
    .companies-creativeproducts {
        margin-bottom: -60px;
    }
}