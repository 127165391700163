/* .homepage-footers-images {
    background-image: url("../../assets/Foot.png");
    background-color: rgba(2, 2, 2, 0.50);
    background-blend-mode: overlay;
} */

.homepage-footers-images {
   /* background-color: rgb(1, 18, 35); */
   background-color: #1d1e27;;
   padding: 20px 20px 20px 20px;
   color: #ffff;
}
.footer-hompage-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: auto;
    margin-top: 0px;
}

.homepage-footer-hr {
    border: 2px solid white;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.footer-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding-left: 80px;
}

.footer-social-icons {
    padding-right: 70px;
}

.social-icon,
.homepage-social-icon-link {
    margin-left: 10px;
    width: 35px;
    height: 35px;
    color: rgb(5, 38, 89);
    border-radius: 50%;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    gap: 10px;
}

.social-icon:hover,
.homepage-social-icon-link:hover {
    transform: scale(1.1);
}

.homepage-social-icon-link {
    color: rgb(5, 38, 89) !important;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
}
.homepage-footer-copyrightp {
    font-size: 16px;
    font-weight: 400;
}

/* background */
.backgroundimages-1 {
    background: linear-gradient(to bottom, rgb(244, 247, 252), rgb(102, 101, 101));
}

.backgroundcolorin-images {
    background-color: rgb(80, 143, 244);
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 80px;
}

.learnerspage-page-banner10-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
}

.buttonget-join-now-btn {
    color: #0d0d0d;
    border: 0px solid;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    background-color: #fcf9f9;
}

.buttonget-join-now-btn:hover {
    background-color: #fcf9f9;
    transform: scale(1.1) translateY(-5px);
}

.buttonget-join-now-btn-link {
    padding-top: 20px;
    margin-left: 10px;
}

.buttonget-join-now-btn-hello-huebits {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 400;
}

.learnerspage-page-banner10-image {
    width: 600px;
    height: 350px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .homepage-footers-images {
        margin-top: -10px;
     }
    .footer-hompage-image {
        width: 150px;
        height: auto;
        margin-left: 0;
        margin-top: 20px;
    }
    .homepage-footer-hr {
        margin: 10px auto;
        width: 100%;
    }
    .footer-social {
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-left: 0;
    }
    .footer-social-icons {
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .social-icon,
    .homepage-social-icon-link {
        width: 30px;
        height: 30px;
        margin: 5px;
        padding: 6px;
    }

    /*background  */
    .backgroundimages-1 {
        height: auto;
        width: auto;
    }
    .backgroundcolorin-images {
        width: auto;
        height: auto;
        border-radius: 0px;
        padding: 0px 0px 20px 0px;
        margin: 0px -10px 0px -10px;
    }
    .backgroundcolorin-images-ul {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .backgroundcolorin-images-ul ul {
        text-align: center;
        padding: 20px 10px 10px 10px;
    }
    .backgroundcolorin-experience-learn-h1{
        font-size: 28px;
        line-height: 1.5rem;
        text-align: center;
        padding: 0px;
    }
    .backgroundcolorin-experience-p{
        font-size: 1.2rem;
        line-height: 2;
        text-align: center;
        padding: 0px 20px 0px 20px;
    }
    .buttonget-join-now-btn {
        width: 160px;
        height: 36px;
        font-size: 16px;
        border-radius: 0px;
        margin-right: 35px;
    }
    .buttonget-join-now-btn-link {
        padding-top: 20px;
    }
    .learnerspage-page-banner10-card{
        padding: 10px 40px;
        align-items: center;
    }
    .learnerspage-page-banner10-image {
        width: 0px;
        height: 0px;
    }
    .footer-content-background-color {
        padding: 20px 0px 20px 0px;
    }
    .students-footer {
        flex-direction: column;
    }
    .footer-content-background-color-huebits {
        font-size: 1.6rem;
        margin: 0px 10px 0px 20px;
    }
    .footer-content-background-color-students {
        margin-left: 20px;
    }
    .footer-content-background-color-companies {
        margin-left: 20px;
    }
}

/* @media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
    .homepage-footer {
        padding: 40px 0px 0px 0px;
        justify-content: center;
    }
    .footer-hompage-image {
        width: 222px;
        height: 54px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        padding: 0px 40px 0px 0px;
    }
    .homepage-footer-hr {
        margin: 10px auto;
        width: 100%;
    }
    .footer-social {
        justify-content: start;
        align-items: start;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    /* background */
    .backgroundcolorin-images {
        height: auto;
        padding: 40px 14px 40px 0px;
    }
    .backgroundcolorin-images-ul {
        justify-content: center;
        text-align: center;
        margin-left: 0px;
    }
    .backgroundcolorin-images-ul ul {
        padding-top: 20px;
    }
    .headway-images,
    .headway-imageses {
        justify-content: start;
        align-items: start;
        margin-left: auto;
        margin-right: auto;
    }
    .headway-image {
        width: 350px;
        height: 350px;
    }
}