/* Main container */

.learning-path-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.learning-path-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin-top: 20px;
}

/* Card styling */

.learning-path-card {
    /* background-color: #86c5fb; */
    background-color: #ffff;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    height: 250px;
    flex: 1 1 calc(30.33% - 40px); /* 3 cards per row */
    max-width: calc(30.33% - 40px);
    box-sizing: border-box;
    margin-bottom: 20px; /* Add margin-bottom to create space between rows */
}

.learning-path-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.learning-path-image {
    width: 160px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 15px;
    /* filter: brightness(0) invert(1); */
}

.learning-path-title {
    font-size: 1rem;
    font-weight: 700;
    color: #052659;
    margin-bottom: 6px;
}

.learning-path-button {
    background-color: rgb(14, 104, 249);
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding: 6px;
    width: 200px;
    margin-top: 10px;
    border: none;
    transition: background 0.3s ease, transform 0.3s ease;
}

.learning-path-button:hover {
    background-color: rgb(7, 100, 248);
    transform: scale(1.05);
}

.coming-soon {
    background: #888;
    cursor: not-allowed;
}
.coming-soons{
    background: #888;
    cursor: not-allowed;
}

.coming-soon:hover,
.coming-soons:hover {
    background: #888;
    transform: scale(1);
}

@media (min-width: 320px) and (max-width: 480px) {
    .learning-path-card {
        flex: 1 1 100%; /* Take full width */
        max-width: 100%;
    }
    .learning-path-container {
        padding: 10px;
    }
    .learning-path-card {
        padding: 10px;
    }
    .learning-path-button {
        margin-top: 0px;
    }
    
}

@media (min-width: 481px) and (max-width: 1023px){
    .learning-path-card {
        flex: 1 1 calc(50% - 20px); /* 2 cards per row */
        max-width: calc(50% - 20px);
    }
    .learning-path-title {
        font-size: 1.1rem;
    }
    .learning-path-image {
        width: 80px;
        height: 60px;
    }
    .learning-path-button {
        font-size: 14px;
        padding: 10px 18px;
        margin-top: 10px;
    }
}

/*  */
@media (min-width: 1024px) and (max-width: 1500px) {
    .learning-path-container {
        padding: 20px;
    }
    
    .learning-path-card {
        background-color: #ffff;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        height: 250px;
        flex: 1 1 calc(33.33% - 40px);
        max-width: calc(33.33% - 40px);
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    
    .learning-path-card {
        padding: 16px;
        height: 220px;
    }
    
    .learning-path-image {
        width: 80px;
        height: 60px;
    }
    
    .learning-path-title {
        font-size: 1.1rem;
        margin-bottom: 20px;
    }
    
    .learning-path-button {
        font-size: 1rem;
        padding: 6px;
    }
}