.students-basic-profile {
    margin: 20px;
}

.students-contact-information {
    flex-direction: column;
}

.students-works {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin: 20px;
}

.students-dashboard-works {
    display: flex;
    flex-direction: row;
    /* background: #f3f3f3; */
    height: auto;
    width: auto;
}

.profile1 {
    position: relative;
    cursor: pointer;
}

.profile2 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.user-profile-pic {
    display: none;
}

.students-profiles {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.user-profile-image {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.students-profile1 {
    display: flex;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.05);
    height: 580px;
    width: 80%;
}

.profile-link a {
    text-decoration: none;
    color: #052659;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px 1px 20px;
    margin-top: 20px;
    border-radius: 10px;
    transition: background 0.3s, color 0.3s;
}

.profile-link a:hover,
.profile-link a.active-basic-link,
.profile-link a.active-contact-link,
.profile-link a.active-academic-link,
.profile-link a.active-skills-link,
.profile-link a.active-areas-link {
    background: #052659;
    color: #fff;
}

.faedit {
    color: black;
    margin-left: 10px;
}

.active-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.myprofilelinks {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
}

.myprofilelink {
    display: none;
}

.myprofilelink.active {
    display: block;
}

.students-profile-update-p {
    color: #052659;
}


/* Media Queries for Mobile Responsiveness */

@media (max-width: 768px) {
    .students-dashboard-works {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }
    .students-profile1 {
        width: 90%;
        height: auto;
        padding: 15px;
    }
    .students-works {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .profile1 {
        width: 100%;
        margin-bottom: 20px;
    }
    .profile-link a {
        font-size: 16px;
        padding: 8px 15px;
    }
    .user-profile-image {
        width: 80px;
        height: 80px;
    }
    .myprofilelinks {
        padding: 10px;
    }
    .active-profile {
        flex-direction: column;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .students-dashboard-works {
        height: 580px;
        padding: 0px;
        margin-bottom: 20px;
    }
    .students-dashboard-works {
        padding: 5px;
    }
    .students-profile1 {
        width: 100%;
        height: auto;
        padding: 10px;
    }
    .profile-link a {
        font-size: 14px;
        padding: 6px 10px;
    }
    .user-profile-image {
        width: 70px;
        height: 70px;
    }
    .myprofilelinks {
        padding: 5px;
    }
}