/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024px) and (max-width: 1500px) {
    .learners-iot-banner1-h1{
        font-size: 40px;
        width: 80%;
    }
    .learners-iot-banner1-p1,
    .learners-iot-banner1-p2,
    .learners-iot-banner1-p3{
        font-size: 1.1rem;
    }
    .learners-iot-banner3-h1,
    .learners-iot-banner4-h1,
    .learners-data-science-banner4-h1,
    .learners-iot-banner5-h1,
    .learners-iot-banner6-h1,
    .learners-iot-banner7-h1,
    .learners-iot-banner8-h1 {
        font-size: 24px;
        border-left: 12px solid #1c84e4;
    }
    .learners-iot-banner9-h1{
        font-size: 24px;
    }
    .learners-iot-banner3-p{
        font-size: 1.05rem;
    }
    .learners-iot-banner4-p,
    .learners-data-science-banner4-p,
    .learners-iot-banner5-p{
        font-size: 1.1rem;
    }
    .learners-iot-banner3-cards,
    .learners-embedded-systems-banner3-cards {
        gap: 5px;
        padding: 10px 0px;
    }

    .learners-iot-banner3-cards p,
    .learners-embedded-systems-banner3-cards p {
        font-size: 0.95rem;
        padding: 16px;
    }

    .learners-iot-banner3-cards p {
        width: 190px;
        height: 105px;
    }

    .learners-embedded-systems-banner3-cards p {
        width: 200px;
        height: 130px;
    }
    .learners-iot-banner4-cards {
        gap: 14px;
        padding: 12px 0px 6px 0px;
    }

    .learners-iot-banner4-card {
        gap: 16px;
        padding: 16px 16px 30px 16px;
    }

    .learners-iot-banner4-card-h2 {
        font-size: 0.95rem;
        line-height: 1.6rem;
    }

    .learners-iot-banner4-card-p {
        font-size: 1.6rem;
    }

    .learners-iot-banner4-card-icon {
        font-size: 3rem;
    }

    .learners-iot-banner4-card-img {
        width: 400px;
        height: 240px;
    }
    .learners-iot-banner5-li {
        font-size: 1rem;
    }
    .learners-iot-banner6-cards {
        padding: 30px 30px 40px 30px;
        gap: 50px;
    }

    .learners-iot-banner6-card-1-p1 {
        font-size: 1rem;
        padding: 0px 6px;
        margin: 16px 0px;
    }

    .learners-iot-banner6-card-image {
        width: 400px;
        height: 250px;
    }
    .learners-iot-tech-talent-banner7-cards {
        gap: 15px;
    }

    .learners-iot-tech-talent-banner7-1,
    .learners-iot-tech-talent-banner7-card-1{
        width: 100%;
        height: 300px;
        padding: 10px;
    }

    .learners-embedded-tech-talent-banner7-card-1 {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .learners-iot-tech-talent-banner7-logos {
        width: 40px;
        height: 40px;
    }

    .learners-iot-tech-talent-banner7-logos-iot {
        width: 50px;
        height: 50px;
        margin: 10px;
    }

    .learners-datascience-tech-talent-banner7-logos {
        width: 50px;
        height: 50px;
    }
}