.students-flex {
    width: 100%;
}

.studentsjoin-span {
    margin-left: 80px;
}

/* .homepagecolores {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
} */

/* .students-getstarted-flex-1 {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #052659 100%);
} */

/* .learnerspage-banner1{
  margin: 20px 0px;
} */
.learnerspage-banner1{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    /* background-color: #25B1FF; */
    width: 100%;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 0.5px solid rgb(239, 239, 239);
    border-bottom: 0.5px solid rgb(196, 218, 252);
    position: relative; /* Ensures child elements respect z-index */
}

.learnerspage-banner1-ul{
    list-style: none;
    flex: 1;
    z-index: 2; /* Ensures text stays above the image */
    position: relative;
    padding-left: 80px;
    padding-top: 16px;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  
}
.learnerspage-banner1-h1{
    width: 900px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.4;
    color: #353536;
}
.learnerspage-banner1-h1 span{
   color: rgb(27, 110, 244);
   /* color: #c4921b; */
   /* color: rgb(255, 255, 4); */
}

.learnerspage-banner1-p1{
  width: 800px;
  font-size: 1.2rem;
  color: #353536;
  /* color: #ffff; */
  line-height: 2;
}

.learnerspage-banner1-p2,
.learnerspage-banner1-p3,
.learnerspage-banner1-p4{
    width: 800px;
    font-size: 1.2rem;
    color: #353536;
    /* color: #ffff; */
    line-height: 1.5;
}
.learnerspage-banner1-ul p span{
  font-size: 1.6rem;
   color: rgb(255, 191, 0);
   /* color: #c4921b; */
   padding-right: 14px;
}


.learnerspage-banner1-btn {
  background-color: rgb(14, 104, 249);
  /* background-color: rgb(0, 0, 0); */
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px 0px 6px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgba(14, 104, 249, 0.3);
}
  
.learnerspage-banner1-btn:hover {
    background-color: rgb(0, 98, 255);
    transform: translateY(-2px);
    box-shadow: 0px 6px 15px rgba(14, 104, 249, 0.5);
}

  .learnerspage-banner1-arrow-arrowright{
    font-size: 18px;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .learnerspage-banner1-btn:hover .learnerspage-banner1-arrow-arrowright {
    transform: translateX(8px);
    color: rgb(0, 0, 0);
  }

  .learnerspage-banner1-images{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 20;
    z-index: 1; 
  }
  .learnerspage-banner1-image{
    max-width: 100%;
    height: 494px;
    object-fit: cover;
  }

   /* paddings */
   .learnerspage-banner3,
   .learnerspage-banner4,
   .learnerspage-banner5,
   .learnerspage-banner6,
   .learnerspage-banner7{
     padding: 20px 80px 20px 80px;
   }

   .learnerspage-banner5{
    background-color: #ffff;
    padding-top: 40px;
   }
   /* .learnerspage-banner2{
   border-top: 0.5px solid rgb(196, 218, 252);
   } */
   .learnerspage-banner2,
   .learnerspage-banner4,
   .learnerspage-banner6,
   .learnerspage-banner8{
    padding: 30px 80px 40px 80px;
    background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
    background-repeat: no-repeat;
  }

  .learnerspage-banner6-btn{
    background-color: rgb(14, 104, 249);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding: 10px 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(14, 104, 249, 0.3);
  }
  .learnerspage-banner6-btn:hover {
    background-color: rgb(0, 98, 255);
    box-shadow: 0px 6px 15px rgba(14, 104, 249, 0.5);
}
  /* h1tags */
  .learnerspage-banner2-h1,
  .learnerspage-banner3-h1,
  .learnerspage-banner4-h1,
  .learnerspage-banner5-h1,
  .learnerspage-banner8-h1{
    color: #052659;
    text-align: left;
    font-size: 28px;
    font-weight: 700;
    border-left: 14px solid rgb(27, 110, 244);
    padding: 0px 10px 0px 10px;
  }
  .learnerspage-banner2-h1 span,
  .learnerspage-banner5-h1 span,
  .learnerspage-banner8-h1 span{
    padding-left: 8px;
  }

  .learnerspage-banner6-h1,
  .learnerspage-banner7-h1{
    color: #052659;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding: 20px 0px;
  }
  .learnerspage-banner3-p,
  .learnerspage-banner3-p1{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #353536;
    text-align: left;
    padding: 0px 0px 0px 31px;
  }
  .learnerspage-banner8-p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #353536;
    text-align: left;
    padding: 16px 0px 0px 14px;
  }
  .learnerspage-banner3-p{
    padding: 14px 0px 0px 0px;
  }
.learnerspage-banner2-p{
    font-size: 1.2rem;
    font-weight: 400;
    color: #353536;
    padding: 16px 0px 0px 10px;
}

  .learnerspage-banner2-ul{
    list-style: none;
    display: flex;
    gap: 20px;
  }

  .learnerspage-banner2-ul li{
    font-weight: 400;
    color: #353536;
  }
  .learnerspage-banner2-ul-icon{
    /* background-color: #1976d2; */
    font-size: 2rem;
    color: rgb(27, 110, 244);
    /* border-radius: 10%; */
    width: 20px;
    height: 20px;
  }

  .learnerspage-banner2-industryreadyiot{
    margin: 40px 0px 20px 0px;
  }

  .learnerspage-banner3{
    background-color: #ffff;
  }
  .learnerspage-banner3-image{
    width: 500px;
    height: 350px;
  }
  .learnerspage-banner4{
    padding-block-end: 60px;
  }
  .learnerspage-banner3-h1{
    margin: 20px 0px 10px 0px;
    padding: 0px 0px 0px 16px;
  }

  .learnerspage-banner4-h1{
    padding: 0px 0px 0px 16px;
  }

  .learnerspage-banner6-button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .learnerspage-banner7{
    background-color: #ffff;
    padding: 40px 0px;
  }
  .learnerspage-banner7-p{
    text-align: center;
    font-size: 1.4rem;
    line-height: 0.5px;
    font-weight: 400;
    color: #353536;
    line-height: 2rem;
  }

  .learnerspage-banner8-cards{
    display:  flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: start;
    gap: 20px;
    padding: 20px 0px 10px 0px;
  }

  .learnerspage-banner8-card1{
    width: 260px;
    height: 220px;
    background-color: #ffffff;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid #add6fc;
    border-radius: 10px;
  }

  .learnerspage-banner8-card1 h1{
    text-align: start;
    font-size: 0.9rem;
    font-weight: 700;
    color: #353536;
    line-height: 1.6;
    padding-top: 20px;
  }
  .learnerspage-banner8-card1-p{
    font-size: 0.9rem;
  }
  .learnerspage-banner8-card1-img{
    width: 80px;
    height: 80px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
  .learnerspage-banner1{
    height: auto;
    padding-top: 8px;
    padding-bottom: 10px;
  }
  .learnerspage-banner1-ul{
    padding: 25px;
}
.learnerspage-banner1-h1{
    inline-size: auto;
    font-size: 1.4rem;
    line-height: 1.6;
}
.learnerspage-banner1-p1{
  width: auto;
  font-size: 1rem;
}

.learnerspage-banner1-p2,
.learnerspage-banner1-p3,
.learnerspage-banner1-p4{
    width: auto;
    font-size: 1rem;
}
.learnerspage-banner1-ul p span{
   padding-right: 10px;
}
.learnerspage-banner1-btn {
  width: auto;
  padding: 10px 40px;
}
.colorwhitecard {
  margin: 0px 20px 20px 20px;
}
.learnerspage-banner1-image{
  width:auto;
  height: auto;
  opacity: 0.4;
  margin-top: -20px;
}

.learnerspage-banner3,
.learnerspage-banner4,
.learnerspage-banner5,
.learnerspage-banner6,
.learnerspage-banner7{
  padding: 20px;
}

.learnerspage-banner5{
  padding-top: 20px;
}

.learnerspage-banner2,
.learnerspage-banner4,
.learnerspage-banner6,
.learnerspage-banner8{
  padding: 20px;
}
.learnerspage-banner2-h1,
.learnerspage-banner3-h1,
.learnerspage-banner4-h1,
.learnerspage-banner5-h1,
.learnerspage-banner8-h1{
  text-align: left;
  font-size: 1.2rem;
  border-left: 0px;
  padding: 0px;
  line-height: 1.6;
}
.learnerspage-banner2-h1 span,
.learnerspage-banner5-h1 span,
.learnerspage-banner8-h1 span{
  padding-left: 0px;
}

.learnerspage-banner6-h1,
.learnerspage-banner7-h1{
  text-align: center;
  font-size: 1.2rem;
  border-left: 0px;
  padding: 0px;
  line-height: 1.6;
}
.learnerspage-banner3-p,
.learnerspage-banner3-p1{
  text-align: left;
  font-size: 1rem;
  padding: 0px;
  line-height: 1.6;
}
.learnerspage-banner3-image{
  width: 320px;
  height: auto;
  margin-top: 20px;
}
.learnerspage-banner8-p,
.learnerspage-banner7-p{
  text-align:left;
  font-size: 1rem;
  padding: 0px;
}
.learnerspage-banner2-p{
  text-align: left;
  font-size: 1rem;
  padding: 0px;
}
.learnerspage-banner4{
  padding-block-end: 20px;
}
.learnerspage-banner3-h1{
  margin: 0px;
  padding: 0px;
}

.learnerspage-banner4-h1{
  padding: 0px;
}
}

/* Tablet Styling */

/* @media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
  .learnerspage-banner1{
    height: auto;
    padding: 0px;
}
.learnerspage-banner1-ul{
  padding: 20px;
}
.learnerspage-banner1-h1{
  width: auto;
  font-size: 46px;
}
.learnerspage-banner1-p1{
  width: auto;
}

.learnerspage-banner1-p2,
.learnerspage-banner1-p3,
.learnerspage-banner1-p4{
  width: auto;
}
.learnerspage-banner1-image{
  opacity: 0.2;
}
  .learnerspage-banner2,
  .learnerspage-banner3,
  .learnerspage-banner4,
  .learnerspage-banner5,
  .learnerspage-banner6,
  .learnerspage-banner7,
  .learnerspage-banner8{
    padding: 20px 20px 34px 20px;
  }
  .learnerspage-banner2-h1,
  .learnerspage-banner3-h1,
  .learnerspage-banner4-h1,
  .learnerspage-banner5-h1,
  .learnerspage-banner8-h1{
    padding: 0px 10px 0px 20px;
  }
  .learnerspage-banner2-h1 span,
  .learnerspage-banner5-h1 span,
  .learnerspage-banner8-h1 span{
    padding-left: 0px;
  }
  .learnerspage-banner3-p{
    padding: 0px 0px 0px 10px;
  }
  .learnerspage-banner3-p1{
    padding-left: 35px;
  }
  .learnerspage-banner8-p{
    padding: 16px 0px 0px 6px;
  }
  .learnerspage-banner3-p{
    padding: 14px 0px 0px 0px;
  }
.learnerspage-banner2-p{
    padding: 10px 0px 0px 0px;
}

}




/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024px) and (max-width: 1500px) {
  .learnerspage-banner1 {
      height: auto;
      padding: 30px 0;
  }

  .learnerspage-banner1-ul {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 60px;
  }
  .learnerspage-banner1-h1 {
      width: 90%;
      font-size: 40px;
      line-height: 1.4;
  }

  /* paddings */
 .learnerspage-banner3,
 .learnerspage-banner4,
 .learnerspage-banner5,
 .learnerspage-banner6,
 .learnerspage-banner7{
   padding: 20px 60px 20px 60px;
 }
 .learnerspage-banner2,
 .learnerspage-banner4,
 .learnerspage-banner6,
 .learnerspage-banner8{
  padding: 30px 60px 40px 60px;
}

  .learnerspage-banner1-p1,
  .learnerspage-banner1-p2,
  .learnerspage-banner1-p3,
  .learnerspage-banner1-p4 {
      width: 750px;
      font-size: 1.1rem;
      line-height: 1.6;
  }

  .learnerspage-banner1-ul p span {
      font-size: 1.4rem;
      padding-right: 10px;
  }

  .learnerspage-banner1-btn {
      font-size: 14px;
      padding: 9px 16px;
      margin-top: 12px;
  }

  .learnerspage-banner1-arrow-arrowright {
      font-size: 15px;
  }

  .learnerspage-banner1-image {
      max-width: 85%;
      height: auto;
  }

  .learnerspage-banner2-h1,
  .learnerspage-banner3-h1,
  .learnerspage-banner4-h1,
  .learnerspage-banner5-h1,
  .learnerspage-banner8-h1{
      font-size: 24px;
      border-left: 12px solid rgb(27, 110, 244);
  }
  .learnerspage-banner6-h1,
  .learnerspage-banner7-h1{
      font-size: 24px;
  }

  .learnerspage-banner3-image{
      width: 350px;
      height: 250px;
    }
}