.organizations-page-banner1{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* background-color: #ffffff; */
    background-color: #25B1FF;
    width: 100%;
    /* height: 83%; */
    height: auto;
    /* padding-top: 15px;
    padding-bottom: 15px; */
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: 0.5px solid rgb(196, 218, 252);
    border-top: 0.5px solid rgb(239, 239, 239);
    position: relative; 
  }
  .organizations-page-banner1-ul{
    list-style: none;
    flex: 1;
    z-index: 2;
    position: relative;
    padding: 40px 0px 40px 80px;
  }
  .organizations-page-banner1-h1{
    width: 900px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.6;
    color: #ffff;
    /* color: #353536; */
    /* margin-top: -40px; */
  }
  .organizations-page-banner1-h1 span{
     /* color: rgb(27, 110, 244); */
     color: rgb(255, 255, 4);
  }
  
  .organizations-page-banner1-p{
    width: 800px;
    font-size: 1.2rem;
    /* color: #353536; */
    color: #ffff;
    line-height: 2;
  }
  .organizations-page-banner1-buttons{
    display: flex;
    gap: 40px;
    margin-left: 4px;
    margin-top: 60px;
  }
  .organizations-page-banner1-btn {
      /* background-color: rgb(27, 110, 244); */
      background-color: #353536;
      color: white;
      width: 240px;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 10px 20px;
      gap: 10px;
      border: none;
      cursor: pointer;
      text-align: center;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }
    
    .organizations-page-banner1-btn:hover {
      /* background-color: rgb(0, 95, 248); */
      background-color: #242424;
      transform: scale(1.05);
    }

    .organizations-page-banner1-btn-arrow{
      font-size: 18px;
      transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
      margin-left: 10px;
    }

    .organizations-page-banner1-btn:hover .organizations-page-banner1-btn-arrow {
      transform: translateX(8px);
      color: #25B1FF;
    }
  
    .organizations-page-banner1-images{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      bottom: 20;
      z-index: 1; 
    }
    .organizations-page-banner1-image{
      max-width: 100%;
      height: 450px;
      object-fit: cover;
    }
    /* paddings */
   .organizations-page-banner3,
   .organizations-page-banner4,
   .organizations-page-banner5,
   .organizations-page-banner6,
   .organizations-page-banner7,
   .organizations-page-banner8,
   .organizations-page-banner10{
        justify-content: center;
        align-items: center;
        padding: 20px 80px 20px 80px;
   }
  .organizations-page-banner3{
   border-top: 0.5px solid rgb(196, 218, 252);
  }

   .organizations-page-banner4,
   .organizations-page-banner6{
        background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
        background-repeat: no-repeat;
        text-align: center;
   }
   .organizations-page-banner10{
    background-color: rgb(80, 143, 244);
   }
  /* h1tags */
  .organizations-page-banner3-h1,
  .organizations-page-banner4-h1,
  .organizations-page-banner5-h1,
  .organizations-page-banner7-h1{
    color: #052659;
    text-align: left;
    font-size: 28px;
    font-weight: 700;
    border-left: 14px solid rgb(27, 110, 244);
    padding: 0px 10px 0px 10px;
  }
  .organizations-page-banner6-h1,
  .organizations-page-banner8-h1{
    color: #052659;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding-block-start: 20px;
  }

  .organizations-page-banner3-h1 span,
  .organizations-page-banner4-h1 span,
  .organizations-page-banner5-h1 span,
  .organizations-page-banner7-h1 span{
    padding-left: 8px;
  }
  .organizations-page-banner3-p,
  .organizations-page-banner7-p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2;
    /* color: #121212; */
    color: #353536;
    text-align: start;
    padding-left: 10px;
  }
  .organizations-page-banner3-h1{
    height: 76px;
  }
  .organizations-page-banner3-p{
    padding: 10px 0px 0px 10px;
  }

  .organizations-page-banner5-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
  }
  .organizations-page-banner5-cards-card1{
    width: 200px;
    height: 180px;
    border: 1px solid rgb(255, 217, 4);
    padding: 10px;
    border-radius: 10px;
  }
  .organizations-page-banner5-cards-icons1{
    width: 80px;
    height: auto;
    padding-top: 10px;
  }
  .organizations-page-banner5-cards-card1-p{
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.4;
    color: #353536;
    text-align: start;
    padding-top: 10px;
  }

  .organizations-page-banner5-cards-icons1-dotted-hr1 {
    border: none;
    border-top: 4px dotted #000; /* Dotted black line */
    width: 60px;
}

.organizations-page-banner5-cards-icons1-dotted-p{
  font-size: 100px;
  margin-top: -42px;
  margin-right: -4px;
}
  .organizations-page-banner7-p{
    margin-left: 20px;
  }

  .organizations-page-banner8-p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2;
    color: rgb(27, 110, 244);
    text-align: center;
  }

  .organizations-page-banner6-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 50px 0px;
  }

 .organizations-page-banner6-cards-card1{
    width: 480px;
    height: 420px;
    background-color: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.organizations-page-banner6-cards-card1:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.18);
  transform: translateY(-5px); 
}


  .organizations-page-banner6-cards-card1-h1 {
    color: #ffffff;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.5;
    background-color: rgb(27, 110, 244);
    border-radius: 10px 10px 0px 0px;
  }

  .organizations-page-banner6-cards-card3{
    padding: 20px 20px 0px 20px;
    text-align: start;
  }
  
  .organizations-page-banner6-cards-card3-card{
    display: flex;
    gap: 10px;
  }
  .organizations-page-banner6-cards-card3-p,
  .organizations-page-banner6-cards-card6-p{
    text-align: start;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    align-items: center;
  }

  .organizations-page-banner6-cards-card3-p-icon{
    padding: 4px;
    color: #ffff;
    background-color: rgb(27, 110, 244);
    margin-top: 1px;
  }

  .organizations-page-banner6-cards-card2-button2{
  padding-top: 90px;
  }

  .organizations-page-banner6-cards-card-bttn{
   background-color: rgb(27, 110, 244);
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 8px 40px;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.organizations-page-banner6-cards-card-bttn-faArrowRight{
  padding-left: 10px;
}

.organizations-page-banner6-cards-card-bttn:hover{
    background-color: rgb(1, 91, 234);
    transform: scale(1.05);
}

.organizations-page-banner7-containers{
  background-color: rgba(234, 252, 255, .4);
  border: 1px solid #cae7ff;
  border-radius: 20px;
  margin-top: 40px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px;
}

.organizations-page-banner7-containers-cards1,
.organizations-page-banner7-containers-cards2{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  gap: 10px;
}

.organizations-page-banner7-containers-cards1{
  padding-bottom: 80px;
}
.organizations-page-banner7-containers-cards-cord{
  width: 500px;
  height: auto;
}

.organizations-page-banner7-containers-cards-cord-h1{
  font-size: 28px;
  font-weight: 700;
  color: #353536;
  padding-bottom: 14px;
}

.organizations-page-banner7-containers-cards-cord-h6{
  font-size: 1.1rem;
  font-weight: 600;
  color: #353536;
  line-height: 1.6;
  padding-top: 6px;
}

.organizations-page-banner7-containers-cards-cord-p{
  font-size: 1.1rem;
  font-weight: 400;
  color: #353536;
  line-height: 1.5;
}
.organizations-page-banner7-containers-cards-cord-img,
.organizations-page-banner7-containers-cards-cord-imageses{
  width: 460px;
  height: 340px;
}

.organizations-page-banner7-containers-cards-cord-Internships{
  width: 0px;
  height: 0px;
}

.organizations-page-banner10-card{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
}

.organizations-page-banner10-ul{
  list-style: none;
}

.organizations-page-banner10-h1{
  color: #ffffff;
  text-align: start;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.6;
}

.organizations-page-banner10-p{
  color: #ffffff;
  text-align: start;
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 400;
}

.organizations-page-banner10-image{
  width: 500px;
  height: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  .organizations-page-banner1{
    flex-direction: column;
    width: 100%;
    height: auto;
    border-bottom: none;
    margin: 0px;
  }
  .organizations-page-banner1-ul{
    padding: 20px;
  }
  .organizations-page-banner1-h1{
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 0px;
  }
  .organizations-page-banner1-p{
    width: 100%;
    font-size: 1.1rem;
  }
  .organizations-page-banner1-buttons{
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 40px 0px 10px 0px;
  }
  .organizations-page-banner1-image{
    opacity: 0.2;
  }
  .organizations-page-banner3,
  .organizations-page-banner4,
  .organizations-page-banner5,
  .organizations-page-banner6,
  .organizations-page-banner7,
  .organizations-page-banner8,
  .organizations-page-banner10{
       padding: 20px;
  }
  .organizations-page-banner3-h1,
  .organizations-page-banner4-h1,
  .organizations-page-banner5-h1,
  .organizations-page-banner7-h1,
  .organizations-page-banner6-h1,
  .organizations-page-banner8-h1,
  .organizations-page-banner10-h1{
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.6;
    border-left: none;
    padding: 0px 10px;
  }

  .organizations-page-banner3-h1 span,
  .organizations-page-banner4-h1 span,
  .organizations-page-banner5-h1 span,
  .organizations-page-banner7-h1 span{
    padding-left: 0px;
  }
  .organizations-page-banner3-p,
  .organizations-page-banner7-p{
    font-size: 1rem;
    text-align: left;
    padding-left: 0px;
  }
  .organizations-page-banner7-p{
    width: 100%;
    margin: 0px;
    padding: 0px 20px 0px 10px;
  }
  .organizations-page-banner3-h1{
    height: auto;
  }
 .organizations-page-banner3-p{
  padding: 10px 0px 0px 0px;
  }
  .organizations-page-banner5-cards {
    flex-direction: column; 
    align-items: center; 
    padding: 20px 0;
  }

  .organizations-page-banner5-cards-card1 {
    width: 90%; 
    height: auto;
    padding: 15px;
    margin-bottom: 15px; 
  }

  .organizations-page-banner5-cards-icons1 {
    width: 60px; 
    padding-top: 5px;
    display: block;
    margin: 0 auto; 
  }

  .organizations-page-banner5-cards-card1-p {
    font-size: 1rem; 
    text-align: center; 
  }

  .organizations-page-banner5-cards-icons1-dotted-hr1 {
    width: 0px; 
  }

  .organizations-page-banner5-cards-icons1-dotted-p {
    font-size: 0px; 
    margin-top: -0px;
  }

  .organizations-page-banner6-cards-card1-h1 {
    font-size: 1rem;
    border-radius: 0px;
  }
  .organizations-page-banner7-containers{
    border-radius: 0px;
    margin-top: 20px;
    padding: 0px;
  }
  .organizations-page-banner7-containers-cards1,
  .organizations-page-banner7-containers-cards2{
   padding: 20px;
}
.organizations-page-banner7-containers-cards-cord-img,
.organizations-page-banner7-containers-cards-cord-Internships{
  width: 280px;
  height: 200px;
}

.organizations-page-banner7-containers-cards-cord-imageses{
  width: 0px;
  height: 0px;
}

.organizations-page-banner10-card {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.organizations-page-banner10-h1,
.organizations-page-banner10-p {
  text-align: left;
}

.organizations-page-banner10-ul {
  padding: 0;
  text-align: left;
  width: 100%;
}

.organizations-page-banner10-image {
  width: 100%;
  max-width: 400px;
}
}

/* Tablet Styling */

/* @media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
  .organizations-page-banner1{
    height: auto;
    padding: 10px;
    border-bottom: none;
  }
  .organizations-page-banner1-ul{
    padding: 20px;
  }
  .organizations-page-banner1-h1{
    width: 100%;
    font-size: 46px;
    margin-top: 10px;
  }
  .organizations-page-banner1-p{
    width: 100%;
    font-size: 1.4rem;
  }
  .organizations-page-banner1-buttons{
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }
  .organizations-page-banner1-image{
    opacity: 0.2;
  }
  .organizations-page-banner3{
    padding: 80px 20px 40px 20px;
  }
  .organizations-page-banner4,
  .organizations-page-banner5,
  .organizations-page-banner6,
  .organizations-page-banner7,
  .organizations-page-banner8,
  .organizations-page-banner10{
       padding: 20px;
  }
  .organizations-page-banner3-h1,
  .organizations-page-banner4-h1,
  .organizations-page-banner5-h1,
  .organizations-page-banner7-h1,
  .organizations-page-banner10-h1{
    padding: 0px 20px;
    line-height: 1.4;
  }
  .organizations-page-banner3-h1 span,
  .organizations-page-banner4-h1 span,
  .organizations-page-banner5-h1 span,
  .organizations-page-banner7-h1 span{
    padding-left: 0px;
  }
  .organizations-page-banner3-h1{
    height: auto;
  }
  .organizations-page-banner3-p,
  .organizations-page-banner7-p{
    padding-left: 4px;
  }
  .organizations-page-banner5-cards {
    flex-direction: row; 
    flex-wrap: wrap; 
    gap: 20px;
    padding: 30px 10px; 
  }

  .organizations-page-banner5-cards-card1 {
    width: 180px; 
    height: auto;
    padding: 15px;
  }

  .organizations-page-banner5-cards-icons1 {
    width: 60px; 
  }

  .organizations-page-banner5-cards-card1-p {
    font-size: 1rem; 
    text-align: center;
  }
  .organizations-page-banner7-containers-cards-cord-img,
  .organizations-page-banner7-containers-cards-cord-Internships{
    width: auto;
    height: auto;
    margin-left: -25px;
}

.organizations-page-banner7-containers-cards-cord-imageses{
  width: 0px;
  height: 0px;
}

.organizations-page-banner10-card {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0px 40px 0px;
}

.organizations-page-banner10-h1{
  text-align: center;
}
.organizations-page-banner10-p {
  font-size: 1.4rem;
  text-align: center;
}
.organizations-page-banner10-ul {
  padding: 0;
  text-align: center;
  width: 100%;
}

.organizations-page-banner10-image {
  width: 100%;
  max-width: 600px;
}
}


/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024) and (max-width: 1500px) {
  .organizations-page-banner1{
      height: auto;
      padding: 10px 0;
  }
  .organizations-page-banner1-h1{
      width: 900px;
      font-size: 40px;
    }
    .organizations-page-banner1-p{
      width: 700px;
      font-size: 1.2rem;
      line-height: 2;
    }
    .organizations-page-banner1-image{
      height: 440px;
    }
     /* paddings */
    .organizations-page-banner3,
    .organizations-page-banner4,
    .organizations-page-banner5,
    .organizations-page-banner6,
    .organizations-page-banner7,
    .organizations-page-banner8,
    .organizations-page-banner10{
          padding: 20px 60px 20px 60px;
    }
    .organizations-page-banner3-h1,
    .organizations-page-banner4-h1,
    .organizations-page-banner5-h1,
    .organizations-page-banner7-h1{
      font-size: 24px;
    }
    .organizations-page-banner6-h1,
    .organizations-page-banner8-h1{
      font-size: 24px;
    }
    .organizations-page-banner3-p,
    .organizations-page-banner7-p{
      font-size: 1.1rem;
    }
    .organizations-page-banner7-containers {
      margin-top: 20px;
      padding: 10px 15px;
  }

  .organizations-page-banner5-cards-card1{
    width: 180px;
    height: 180px;
  }

  .organizations-page-banner7-containers-cards1,
  .organizations-page-banner7-containers-cards2 {
      padding: 10px 20px;
      gap: 6px;
  }

  .organizations-page-banner7-containers-cards1 {
      padding-bottom: 50px;
  }

  .organizations-page-banner7-containers-cards-cord {
      width: 450px;
  }

  .organizations-page-banner7-containers-cards-cord-h1 {
      font-size: 24px;
      padding-bottom: 10px;
  }

  .organizations-page-banner7-containers-cards-cord-h6 {
      font-size: 0.95rem;
      line-height: 1.4;
  }

  .organizations-page-banner7-containers-cards-cord-p {
      font-size: 0.95rem;
      line-height: 1.3;
  }

  .organizations-page-banner7-containers-cards-cord-img,
  .organizations-page-banner7-containers-cards-cord-imageses {
      inline-size: 400px;
      block-size: 290px;
  }
}