.hoescoreprogresscontainer {
    background-color: #97bbdf;
    padding-top: 4px;
    height: 200px;
    border-radius: 20px;
    width: 260px;
    margin-left: auto;
    margin-right: auto;
}

.hoescoreprogressheading {
    margin-top: 25px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #052659;
}

.hoescoreprogress {
    width: 180px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4px;
}

.gauge-chart-container {
    position: relative;
}

.needle-base {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.4rem;
    font-weight: 700;
    color: #052659;
}

@media (min-width: 320px) and (max-width: 480px) {
    .hoescoreprogresscontainer {
        height: 200px;
        width: 270px;
        margin: 20px;
    }
    .hoescoreprogressheading {
        margin-top: 10px;
        padding-bottom: 10px;
    }
    .hoescoreprogress {
        width: 250px;
        margin-top: 0px;
    }
    .gauge-chart-container {
        position: relative;
    }
    .needle-base {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.4rem;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px) {
    .hoescoreprogresscontainer {
        width: 220px;
        height: 200px;
    }
    .hoescoreprogress {
        width: 140px;
        height: 45px;
        margin-top: 0px;
    }
    .vertical-progress-heading {
        font-size: 16px;
    }
}

@media (min-width: 1024px) and (max-width: 1500px) {
    .hoescoreprogresscontainer {
        height: 180px;
        width: 240px;
    }
}