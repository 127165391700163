.about-banner,
.careers-banner{
    padding: 0px;
}

.about-banner1,
.careers-banner1 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.about-banner1{
    width: 100%;
    height: 200px;
}

.about-banner1,
.careers-banner1 {
    width: 100%;
    height: 260px;
}

.about-banner1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/AboutUs/AboutUs.jpg');
    background-size: cover;
    background-position: center;
    z-index: -2;
}

.careers-banner1::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/AboutUs/careers.png');
    background-size: cover;
    background-position: center;
    z-index: -2;
}

.about-banner1::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1;
}

.careers-banner1::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 104, 104, 0.5); /* Black overlay with 50% opacity */
    z-index: -1;
}

.about-banner1-h1,
.careers-banner1-h1{
    color: #ffffff;
    font-size: 50px;
    padding: 0px 10px;
    gap: 10px;
    position: relative;
    z-index: 1;
}

.about-banner1-p,
.careers-banner1-p {
    color: #ffffff;
    font-size: 1.2rem;
    line-height: 1.6;
    position: relative;
    z-index: 1;
}

/*  */
.about-banner3,
.about-banner4,
.about-banner5,
.about-banner7,
.careers-banner3{
    padding: 40px 100px 40px 100px;
}
.about-banner2,
.careers-banner2,
.about-banner6{
    background: #ffff;
    padding: 40px 120px 40px 120px;
}
.about-banner3,
.about-banner7{
    background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
}
.about-banner4{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.about-banner5{
    background-color: rgb(15, 33, 61);
    text-align: center;
}
.about-banner2-h1,
.about-banner3-h1,
.about-banner7-h1,
.careers-banner2-h1{
    text-align: center;
    font-size: 46px;
    color: #49505b !important;
}
.about-banner4-h1{
    text-align: start;
    font-size: 40px;
    color: #49505b;
}
.about-banner5-h1{
    text-align: center;
    font-size: 46px;
    color: #ffffff;
}
.about-banner2-p{
    font-size: 1.2rem;
    color: #131415;
    line-height: 2;
    padding-top: 20px;
}
.about-banner2-p1{
    padding-top: 10px;
}
.about-banner2-p span{
    font-weight: 600;
}
.about-banner4-p{
    font-size: 1.1rem;
    color: #131415;
    line-height: 2;
}

/*  */

.about-banner3-vision, .about-banner3-Mission {
    background: rgba(255, 255, 255, 0.1);
    padding: 30px;
    margin: 20px auto;
    width: 85%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.2);
}

/* Vision & Mission Section */
.about-banner3-vision-h2, .about-banner3-Mission-h2 {
    font-size: 32px;
    color: rgb(27, 110, 244);
    margin-bottom: 15px;
    padding-left: 14px;
}

.about-banner3-vision-h2{
    border-left: 10px solid #49505b;
}

.about-banner3-Mission-h2{
    border-left: 10px solid rgb(2, 41, 100);
}

.about-banner3-vision-p, .about-banner3-Mission-p {
    font-size: 1.2rem;
    line-height: 2;
    color: #131415;
}
  
.about-banner4-message{
    width: 60%;
    height: auto;
}

.about-banner4-image,
.about-banner4-img{
    width: 320px;
    height: auto;
    margin-right: 10px;
}

.about-banner4-imgs,
.about-banner4-images{
    width: 0px;
    height: 0px;
}

.about-banner7-cards1,
.about-banner7-cards2{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 40px 0px 20px 0px;

}
.about-banner7-card{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 50%;
    height: 260px;
    background-color: #ffff;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.about-banner7-card-image{
    border-radius: 50%;
    width: 180px;
    height: 180px;
    /* border: 1px solid rgb(155, 155, 155); */
    background-color: #ffffff;
}
.about-banner7-card-image img{
    border-radius: 50%;
    width: 180px;
    height: 180px;
    padding: 6px;
}

.about-banner7-card-p{
    list-style: none;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #131415;
}

.aboutus-slider-container {
    width: 80%;
    margin: auto;
    padding: 20px 0;
  }
  
  .slider-image {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .slider-image:hover {
    transform: scale(1.05);
  }
  
  /* Modal Styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  /* carers */
/* General Styling */
.careers-banner2 {
    text-align: center;
    padding: 50px 80px;
    background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
    color: rgb(36, 36, 36);
}

/* Cards Container */
.careers-banner2-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    justify-content: center;
    margin: 0 auto;
    padding: 20px;
}

/* Individual Card */
.careers-banner2-card,
.careers-banner2-cards-card {
    background: white;
    color: #333;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
}

.careers-banner2-cards-card{
    width: 50%;
    margin-top: 20px;
}

/* Card Hover Effect */
.careers-banner2-card:hover,
.careers-banner2-cards-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Headings inside Cards */
.careers-banner2-card-h2 {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 10px;
}

/* Paragraph inside Cards */
.careers-banner2-card-p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
}

/* Apply Now Button */
.careers-banner2-card a,
.careers-banner2-cards-card a {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 8px;
    transition: background 0.3s ease;
}

/* Button Hover */
.careers-banner2-card a:hover,
.careers-banner2-cards-card a:hover {
    background: #0056b3;
}

.careers-banner-btn {
    background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient Blue */
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
  }
  
  .careers-banner-btn:hover {
    background: linear-gradient(135deg, #0056b3, #003f7f);
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .careers-banner-btn:focus {
    outline: 3px solid rgba(0, 123, 255, 0.5);
  }
  
@media (min-width: 320px) and (max-width: 480px) {
    /* Reduce banner height for small screens */
    .about-banner1, .careers-banner1 { height: 180px; }
    
    /* Reduce font sizes for better readability */
    .about-banner1-h1, .careers-banner1-h1 { font-size: 32px; }
    .about-banner1-p, .careers-banner1-p { font-size: 1rem; line-height: 1.4; }
    
    /* Adjust section paddings */
    .about-banner2, .careers-banner2, .about-banner6 { padding: 20px; }
    .about-banner3, .about-banner4, .about-banner5, .about-banner7, .careers-banner3 { padding: 20px; }

    /* Reduce heading sizes */
    .about-banner2-h1, .about-banner3-h1, .about-banner7-h1, .careers-banner2-h1 { font-size: 32px; }
    .about-banner4-h1, .about-banner5-h1 { font-size: 28px; }

    /* Make images smaller */
    .about-banner4-message { width: 100%; }
    .about-banner4-image, .about-banner4-img { width: 0px; margin: 0 auto; }
    .about-banner4-images{
        width: 200px;
        height: auto; 
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
    }
    .about-banner4-imgs{
        width: 0px;
        height: 0px;
    }

    /* Adjust vision & mission cards */
    .about-banner3-vision, .about-banner3-Mission { width: 100%; padding: 20px; }

    /* Adjust team member cards */
    .about-banner7-cards1, .about-banner7-cards2 { flex-direction: column; gap: 15px; }
    .about-banner7-card { width: 100%; height: auto; flex-direction: column; text-align: start; }

    /* Adjust Careers section */
    .careers-banner2 { padding: 20px; }
    .careers-banner2-cards { grid-template-columns: 1fr; gap: 20px; }
    .careers-banner2-cards-card { width: 100%; }

    /* Adjust Apply Now button */
    .careers-banner2-card a, .careers-banner2-cards-card a { font-size: 0.9rem; padding: 8px 16px; }
}

@media (min-width: 481px) and (max-width: 1023px) {
    /* Adjust banner height */
    .about-banner1,
    .careers-banner1 {
        height: 200px;
    }

    /* Reduce padding for better spacing */
    .about-banner3,
    .about-banner4,
    .about-banner5,
    .about-banner7,
    .careers-banner3 {
        padding: 20px;
    }

    .about-banner2,
    .careers-banner2,
    .about-banner6 {
        padding: 20px;
    }

    /* Adjust heading sizes for readability */
    .about-banner2-h1,
    .about-banner3-h1,
    .about-banner7-h1,
    .careers-banner2-h1 {
        font-size: 36px;
    }

    .about-banner4-h1 {
        font-size: 32px;
    }

    .about-banner5-h1 {
        font-size: 38px;
    }

    /* Adjust paragraphs for better readability */
    .about-banner2-p,
    .about-banner4-p {
        font-size: 1rem;
    }

    /* Reduce width of message section */
    .about-banner4-message {
        width: 80%;
    }

    /* Adjust image sizes */
    .about-banner4-img {
        width: 0px;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    .about-banner4-images{
        width: 250px;
        height: auto; 
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
    }

    /* Adjust banner card layout */
    .about-banner7-cards1,
    .about-banner7-cards2 {
        flex-direction: column;
        align-items: center;
    }

    .about-banner7-card {
        width: 90%;
        height: auto;
        padding: 20px;
    }

    /* Reduce image size inside cards */
    .about-banner7-card-image {
        width: 150px;
        height: 150px;
    }

    .about-banner7-card-image img {
        width: 150px;
        height: 150px;
    }

    /* Careers section grid adjustments */
    .careers-banner2-cards {
        grid-template-columns: 1fr;
    }

    .careers-banner2-cards-card {
        width: 100%;
    }
}




/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024px) and (max-width: 1500px) {
    /* General Banner Adjustments */
    .about-banner3,
    .about-banner4,
    .about-banner5,
    .about-banner7,
    .careers-banner3{
       padding: 40px 80px 40px 80px;
    }
    .about-banner2,
    .careers-banner2,
    .about-banner6{
       background: #ffff;
       padding: 40px 100px 40px 100px;
    }
 .about-banner1, .careers-banner1 { height: 220px; }
 .about-banner1-h1, .careers-banner1-h1 { font-size: 40px; }
 .about-banner1-p, .careers-banner1-p { font-size: 1rem; line-height: 1.4; }
 
 /* Section Padding & Headings */
 .about-banner2, .careers-banner2, .about-banner6,
 .about-banner3, .careers-banner3 { padding: 30px 60px; }
 .about-banner2-h1, .about-banner3-h1, .about-banner7-h1, .careers-banner2-h1 { font-size: 40px; }
 .about-banner4-h1 { font-size: 36px; }
 .about-banner5-h1 { font-size: 40px; }
 .about-banner2-p, .about-banner4-p { font-size: 1rem; line-height: 1.8; }
 
 /* Vision & Mission Cards */
 .about-banner3-vision, .about-banner3-Mission { width: 90%; padding: 25px; }
 .about-banner3-vision-h2, .about-banner3-Mission-h2 { font-size: 28px; }
 .about-banner3-vision-p, .about-banner3-Mission-p { font-size: 1rem; line-height: 1.8; }
 
 /* Images & Team Cards */
 .about-banner4-message { width: 60%; }
 .about-banner4-image, .about-banner4-img { width: 250px; margin-right: 8px; }
 .about-banner7-cards1, .about-banner7-cards2 { gap: 20px; }
 .about-banner7-card { width: 100%; height: auto; padding: 10px; }
 .about-banner7-card-image, .about-banner7-card-image img { width: 150px; height: 150px; }
 .about-banner7-card-p{
    list-style: none;
    font-size: 1rem;
    line-height: 1.6;
    color: #131415;
 }
 /* Careers Section */
 .careers-banner2 { padding: 40px 60px; }
 .careers-banner2-cards { grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); gap: 30px; padding: 15px; }
 .careers-banner2-card  { padding: 15px; border-radius: 12px; width: 100%; }
 .careers-banner2-cards-card { padding: 15px; border-radius: 12px; width: 50%; }
 .careers-banner2-card-h2 { font-size: 1.3rem; }
 .careers-banner2-card-p { font-size: 0.95rem; line-height: 1.5; }
 .careers-banner2-card a, .careers-banner2-cards-card a { padding: 8px 18px; font-size: 0.9rem; border-radius: 6px; }
 
 /* Slider & Modal */
 .aboutus-slider-container { width: 90%; }
 .slider-image, .modal-content img { border-radius: 8px; }
 .modal-content { max-width: 85%; }
 }




  
  
  