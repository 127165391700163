/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024px) and (max-width: 1500px) {
  .organization-iot-tech-talent-banners1,
  .organization-data-tech-talent-banners1,
  .organization-embedded-tech-talent-banners1 {
    width: 100%; 
    height: 520px; 
}
  .organization-iot-tech-talent-banner1 h1 {
      font-size: 40px;
  }
  .organization-iot-tech-talent-banner1 p {
      font-size: 1.2rem;
  }
  .organization-iot-tech-talent-banner2-h1,
  .organization-iot-tech-talent-banner3 h1,
  .organization-iot-tech-talent-banner4-h1,
  .organization-iot-tech-talent-banner5 h1,
  .organization-iot-tech-talent-banner6 h1 {
      font-size: 22px;
      padding: 0px 6px 0px 14px;
      border-left: 12px solid rgb(27, 110, 244);
  }

  .organization-iot-tech-talent-banner2-card {
      text-align: center;
      padding: 16px 24px;
  }

  .organization-iot-tech-talent-banner2-p {
      font-size: 1rem;
      width: 70%;
  }

  .organization-iot-tech-talent-banner2-button {
      align-items: center;
      gap: 15px;
  }

  .organization-iot-tech-talent-banner2-button button {
      font-size: 1rem;
      padding: 8px 16px;
  }

  .organization-iot-tech-talent-banner2-image img {
      width: 500px;
  }
  .organization-iot-tech-talent-banner4-div1-p{
    font-size: 1.1rem;
    padding: 10px 0px 0px 30px;
  }
  .organization-iot-tech-talent-banner4-card {
    gap: 20px;
    padding: 40px 20px;
}

.organization-iot-tech-talent-banner4-card > div {
    height: 150px;
    width: 300px;
}
.organization-iot-tech-talent-banner4-card p {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    text-transform: capitalize;
}
.organization-iot-tech-talent-banner5-1,
  .organization-iot-tech-talent-banner5-2,
  .organization-iot-tech-talent-banner5-data-1,
  .organization-embedded-tech-talent-banner5-1 {
    width: 45%; /* Adjust width for better layout */
    height: auto; /* Allow content to adjust */
  }

  .organization-iot-tech-talent-banner5-cards {
    gap: 15px; /* Increase spacing for clarity */
    padding-top: 10px;
  }

  .organization-iot-tech-talent-banner5-logos-iot,
  .organization-embedded-tech-talent-banner5-logos {
    width: 55px; /* Reduce logo size */
    height: 55px;
    margin: 15px;
  }

  .organization-iot-tech-talent-banner5-1 h2,
  .organization-iot-tech-talent-banner5-2 h2 {
    font-size: 1rem; /* Reduce font size slightly */
  }
.organization-iot-tech-talent-banner6-p{
  font-size: 1.1rem;
  padding: 6px 0px 0px 30px;
}
.organization-iot-tech-talent-banner6-step {
  max-width: 160px;
  margin-right: 10px;
}
}