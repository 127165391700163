.homepagecolores {
    /* background-color: rgb(255, 255, 255); */
    inline-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    block-size: 91%;
}
  

/* .homepagecolores {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%);
} */
/* @keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.homepagecolores {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%);
    background-size: 200% 200%;
    animation: gradientAnimation 5s infinite linear;
} */
/* 
.homepage-headerpage{
    position: sticky;
    top: 0;
    z-index: 1000; 
    background-color: white; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
} */

.homepage-banner1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    /* background-color: #25B1FF; */
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 0.5px solid rgb(239, 239, 239);
    border-bottom: 0.5px solid rgb(196, 218, 252);
    position: relative;
}

.homepage-banner1 ul {
    list-style: none;
    flex: 1;
    z-index: 2; /* Ensures text stays above the image */
    position: relative;
    padding: 40px 0px 40px 80px;
    /* padding-top: 20px;
    padding-bottom: 20px; */
}

.homepage-banner1-spark-h1 {
    width: 900px;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.6;
    color: #353536;
}

.homepage-banner1 span {
    color: rgb(27, 110, 244);
}

.homepage-banner1-spark-p {
    width: 800px;
    font-size: 1.2rem;
    color: #353536;
    /* color: #ffff; */
    line-height: 2;
}

.homepage-banner1-buttons {
    display: flex;
    gap: 15px;
    list-style: none;
    padding: 60px 0px 0px 0px;
  }
  
  .homepage-banner1-buttons button {
    background-color: rgb(14, 104, 249);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(14, 104, 249, 0.3);
  }
  
  .homepage-banner1-buttons button:hover {
    background-color: rgb(0, 98, 255);
    transform: translateY(-2px);
    box-shadow: 0px 6px 15px rgba(14, 104, 249, 0.5);
  }
  
  .homepage-banner1-buttons button:active {
    background-color: rgb(8, 66, 160);
    transform: translateY(0);
    box-shadow: 0px 3px 8px rgba(14, 104, 249, 0.4);
  }
  
  .homepage-banner1-button-arrow-arrowright {
    font-size: 18px;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .homepage-banner1-buttons button:hover .homepage-banner1-button-arrow-arrowright {
    transform: translateX(8px);
    color: rgb(0, 0, 0);
  }
  
  .homepage-banner1-images {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 20;
    z-index: 1; 
}

.homepage-banner1-image {
    max-width: 100%;
    height: 420px;
    object-fit: cover;
  }

/* 
padding center
 */
 .homepage-banner2{
     justify-content: center;
     align-items: center;
     padding-inline-start: 80px;
     padding-inline-end: 80px;
     padding-block-start: 30px; 
     /* padding-top: 30px; */
 }
 .homepage-banner3,
 .homepage-banner5,
 .homepage-banner6,
 .homepage-banner7,
 .homepage-banner8{
     justify-content: center;
     align-items: center;
     padding-left: 80px;
     padding-right: 80px;
     padding-top: 40px;
 }

 .homepage-banner3,
 .homepage-banner5,
 .homepage-banner6{
    padding-block-end: 40px;
}
.homepage-banner6{
    padding-block-start: 40px;
}

 .homepage-banner7,
 .homepage-banner8{
    background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
    padding-bottom: 40px;
 }
 .homepage-banner2,
.homepage-banner5{
    background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
    background-repeat: no-repeat;
    text-align: center;
}
/* 
h1 fontsizes
 */
 .homepage-banner2-h1,
 .homepage-banner3-h1,
 .homepage-banner5-h1,
 .homepage-banner6-h1,
 .homepage-banner7-h1,
 .homepage-banner9-h1{
    /* color: #303141; */
    color: #052659;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    border-left: 14px solid rgb(27, 110, 244);
    padding: 0px 10px 0px 10px;
    gap: 10px;
}

/* .homepage-banner6-h1{
    height: 68px;
} */
.homepage-banner2-h1 span,
 .homepage-banner3-h1 span,
 .homepage-banner5-h1 span,
 .homepage-banner6-h1 span,
 .homepage-banner7-h1 span{
    margin-left: 8px;
}

.homepage-banner2-h1 .span,
.homepage-banner6-h1 .span,
.homepage-banner7-h1 .Solutions{
    color: rgb(27, 110, 244);
}

.homepage-banner9-h1{
    text-align: center;
    border-left: 0px solid rgb(27, 110, 244);
}
/* banner2 */
.homepage-banner2-h1{
    height: 68px;
}

.homepage-banner2-p,
.homepage-banner6-p{
    /* color: #303141; */
    color: #353536;
    text-align: left;
    font-size: 1.2rem;
    line-height: 2.4;
    font-weight: 400;
}
.homepage-banner5-p{
    color: #353536;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    padding: 10px 0px 0px 10px;
}
.homepage-banner2-h2{
    font-weight: 600;
    font-size: 24px;
    color: rgb(5, 38, 89);
    text-align: center;
    padding: 20px 0px 40px 0px;
}
/* .homepage-banner2-h2-icon-users {
    font-size: 24px;
    color: rgb(0, 0, 0);
    margin-left: 10px;
} */

.homepage-banner2-card1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* justify-content: center;
    align-items: center; */
    text-align: center;
    gap: 10px;
    padding: 40px 0px 20px 0px;
}

.homepage-banner2-card-img{
    width: 100px;
    height: auto;
    color: rgb(27, 110, 244);
}

.homepage-banner2-card2-1 {
    width: 185px;
    height: 250px;
    background-color: #ffffff;
    padding: 14px;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Hover effect */
.homepage-banner2-card2-1:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #ffffff, #f0f5ff);
}

.homepage-banner2-card2-1-p{
    font-size: 1.0rem;
    font-weight: 500;
    color: #353536;
    padding-top: 10px;
}

.homepagehubits-courses-learners-banner2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-around;
    text-align: center;
    gap: 10px;
    padding: 40px 0px 60px 0px;
}
.homepagehubits-courses-learners-banner2-card1,
.homepagehubits-courses-learners-banner2-cards2{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}
.homepagehubits-courses-learners-banner2-cards2{
    margin-top: 40px;
}
.homepagehubits-courses-learners-banner2-cards1{
    width: 240px;
    height: 150px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(202, 220, 247);
    padding: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.homepagehubits-courses-learners-banner2-cards1:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

/* Pulse Animation on Hover */
.homepagehubits-courses-learners-banner2-cards1:hover {
    animation: pulse 0.6s ease-in-out infinite alternate;
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}

.homepagehubits-courses-learners-banner2-cards1-h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(27, 110, 244);
    margin: 0;
}

.homepagehubits-courses-learners-banner2-cards1-p1 {
    font-size: 1.1rem;
    /* color: #1c84e4; */
    color: rgb(27, 110, 244);
    margin-top: 6px;
}

.homepagehubits-courses-learners-banner2-cards-image {
    width: 540px;
    height: 340px;
    border-radius: 12px;
    margin-left: 20px;
}

.homepage-banner3-p {
    text-align: start;
    font-size: 1.2rem;
    line-height: 2;
    font-size: 400;
    color: #353536;
    padding: 0px 35px;
}

.homepage-banner5-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding: 40px 0px 40px 0px;
}
.dotted-hr {
    border: none;
    border-top: 4px dotted #000; /* Dotted black line */
    width: 84%;
    margin-bottom: -120px;
    margin-right: 96px;
}
.homepage-banner5-cards-card1 h4{
    color: #353536;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 20px;
    margin-left: -50px;
}
.homepage-banner5-cards-card1-img {
    background-color: #1c84e4;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 20px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    position: relative; /* Required for absolute positioning */
    cursor: pointer;
}
.homepage-banner5-cards-card1-icons1{
    width: 65px;
    height: 65px;
    /* color: #ffff; */
}
.homepage-banner5-cards-card1-icons2{
    width: 75px;
    height: 75px;
}
.homepage-banner5-cards-card1-icons3{
    width: 95px;
    height: 95px;
}
.homepage-banner5-cards-card1-icons4{
    width: 90px;
    height: 90px;
    padding-right: 4px;
}
.homepage-banner5-cards-card1-icons5{
    width: 75px;
    height: 75px;
}
.homepage-banner5-cards-card1-icons6{
    width: 95px;
    height: 95px;
}
.homepage-banner5-cards-card1-icons7 {
    width: 85px;
    height: 85px;
}

/* Hide <p> by default */
.homepage-banner5-cards-card1-img .homepage-banner5-cards-card1-p {
    display: none;
    position: absolute;
    background: #1c84e4;
    color: #ffff;
    padding: 20px;
    border-radius: 10px;
    width: 240px;
    height: 290px;
    margin-top: 100px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
    font-weight: 400;
    z-index: 1;
}
.homepage-banner5-cards-card1-icons1-p{
    margin-top: 14px;
    text-align: left;
}
.homepage-banner5-cards-card1-icons2-p{
    margin-top: 10px;
    text-align: left;
}
.homepage-banner5-cards-card1-icons3-p,
.homepage-banner5-cards-card1-icons4-p{
    margin-top: -4px;
    text-align: left;
}

.homepage-banner5-cards-card1-icons5-p{
    margin-top: 10px;
    text-align: left;
}

.homepage-banner5-cards-card1-icons1-p1,
.homepage-banner5-cards-card1-icons2-p1,
.homepage-banner5-cards-card1-icons3-p1,
.homepage-banner5-cards-card1-icons4-p1,
.homepage-banner5-cards-card1-icons5-p1,
.homepage-banner5-cards-card1-icons6-p1,
.homepage-banner5-cards-card1-icons7-p1{
    text-align: left;
    margin-top: -10px;
}
.homepage-banner5-cards-card1-icons6-p{
    margin-top: 4px;
    text-align: left;
}
.homepage-banner5-cards-card1-icons7-p{
    margin-top: 10px;
    text-align: left;
}

/* Show <p> on hover */
.homepage-banner5-cards-card1-img:hover .homepage-banner5-cards-card1-p {
    display: block;
}


.homepage-banner5-cards-card1{
    width: 150px;
    height: 200px;
}
.homepage-banner5-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 50px 0px 0px;
}
.homepage-banner5-botton{
    background-color: rgb(14, 104, 249);
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.homepage-banner5-botton:hover {
    background-color: rgb(2, 72, 184);
    transform: scale(1.05);
}
/* organizations */
.homepage-banner6-card{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-around;
    /* padding: 40px 60px 20px 60px; */
    padding-top: 40px;
    gap: 10px;
}
.homepage-banner6-card1,
.homepage-banner6-card2{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.homepage-banner6-card2{
    padding-top: 20px;
}
.homepage-banner6-card1-1 {
    inline-size: 250px;
    block-size: 150px;
    padding: 15px;
    margin-block-end: 20px;
    color: rgb(5, 38, 89);
    font-weight: 500;
    font-size: 1.2rem;
    border-radius: 10px;
    border: 1px solid #1c84e4;
    text-align: start;
  }

  .homepage-banner6-card1-1:hover{
    background-color: #1c84e4;
    color: #ffff;
  }
  /* @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  } */
  
  .homepage-banner6-icon {
    font-size: 2rem;
    padding-block-end: 20px;
    /* animation: pulse 1.5s infinite ease-in-out; */
  }  
  

  .homepage-banner6-card-img{
    inline-size: 550px;
    block-size: 350px;
    border-radius: 20px;
    margin-top: -15px;
  }
  
  .homepage-banner8-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
  }
  
  /* Card 1 - Text Section */
  .homepage-banner8-card1 {
    flex: 1;
    margin-top: 20px;
  }
  
  .homepage-banner8-card1-p {
    display: flex;
    align-items: start;
    width: 90%;
    font-size: 20px;
    font-weight: 400;
    color: #353536;
    padding: 0px 10px 0px 10px;
    margin: 20px 0px 26px 0px;
    gap: 10px; 
    border-left: 6px solid #007bff; 
}
  
 .homepage-banner8-card1-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 40px;
  }
  
  .homepage-banner8-card1-botton {
    background-color: rgb(14, 104, 249);
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .homepage-banner8-card1-botton:hover {
    background-color: rgb(2, 72, 184);
    transform: scale(1.05);
  }
  
  .homepage-banner8-card1-botton:focus {
    outline: none;
  }
  .homepage-banner8-card1-botton-faArrowRight{
    margin-left: 14px;
  }
  
  /* Card 2 - Image Section */
  .homepage-banner8-card2 {
    flex: 1;
    max-width: 100%;
  }
  
  .homepage-banner8-card2-image {
    width: 500px;
    height: 300px;
    border-radius: 20px;
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  .homepage-banner8-card2-image:hover {
    transform: scale(1.08);
    opacity: 0.85;
  }

  .homepage-banner9{
    background-color: #ffff;
    padding: 20px 0px;
  }

  .homepage-banner9-p{
    font-size: 1.4rem;
    color: #353536;
    text-align: center;
  }

.slick-prev,
.slick-next {
    /* background-color: rgb(5, 38, 89); */
    background-color: #1c84e4;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
    /* background-color: rgb(4, 56, 133); */
    background-color: #1c84e4;
}

  @media (min-width: 320px) and (max-width: 480px) {
    .homepagecolores {
        block-size: 91%;
    }
    .homepage-banner1 {
        inline-size: auto;
        block-size: auto;
        padding-block-start: 20px;
        padding-bottom: 40px;
        border-top: 0.5px solid rgb(189, 188, 188);
    }
    .homepage-banner1 ul {
        padding: 10px 30px 0px 30px;
    }
    .homepage-banner1-spark-h1 {
        width: auto;
        font-size: 1.6rem;
    }
    .homepage-banner1-spark-p{
        width: auto;
        font-size: 1rem;
    }

    .homepage-banner1-buttons{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 10px;
    }
    .homepage-banner1-buttons button{
        font-size: 1.1rem;
        width: 280px;
        padding: 10px 10px;
    }
    .homepage-banner1-buttons-icon{
        padding-left: 10px;
    }
    
    .homepage-banner1-image{
        width: auto;
        height: auto;
        opacity: 0.4;
        margin-top: -80px;
    }
    /* 
    padding center
    */
    .homepage-banner2,
    .homepage-banner3,
    .homepage-banner5,
    .homepage-banner6,
    .homepage-banner7,
    .homepage-banner8{
        padding: 20px;
    }
    /* 
    h1 fontsizes
    */
    .homepage-banner2-h1,
    .homepage-banner3-h1,
    .homepage-banner5-h1,
    .homepage-banner6-h1,
    .homepage-banner7-h1,
    .homepage-banner9-h1{
        font-size: 1.2rem;
        text-align: left;
        border-left: 0px solid rgb(27, 110, 244);
        padding: 0px 10px;
        line-height: 1.5;
    }
    .homepage-banner2-h1 span,
    .homepage-banner3-h1 span,
    .homepage-banner5-h1 span,
    .homepage-banner6-h1 span,
    .homepage-banner7-h1 span{
       margin-left: 0px;
   }
    /* banner2 */
    .homepage-banner2-h1{
        height: auto;
    }
    .homepage-banner2-p,
    .homepage-banner6-p,
    .homepage-banner5-p{
        text-align: left;
        line-height: 2;
        font-size: 1rem;
   }
   .homepage-banner3-p {
    font-size: 1rem;
    text-align: left;
    padding: 6px 10px;
}
    .homepage-banner2-h2{
        font-size: 22px;
        padding: 10px;
    }
    .homepage-banner2-card1{
       justify-content: center;
       align-items: center;
       gap: 10px;
       padding-top: 20px;
       padding-bottom: 20px;
    }
    
    .homepage-banner2-card-img{
        width: 100px;
        height: auto;
    }
    .homepage-banner2-card2-1 {
        width: 150px;
        max-width: 180px;
        height: 240px;
        padding: 8px;
        border-radius: 0px;
        margin-top: 20px;
    }
    .homepage-banner2-card2-1:hover {
        transform: translateY(-6px);
    }

    .homepagehubits-courses-learners-banner2 {
        gap: 40px;
        padding-block-end: 10px;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }
    .homepagehubits-courses-learners-banner2-card1,
    .homepagehubits-courses-learners-banner2-cards2{
        gap: 20px;
    }
    .homepagehubits-courses-learners-banner2-cards2{
        margin-top: 20px;
    }
    .homepagehubits-courses-learners-banner2-cards1{
        width: 290px;
        height: 150px;
        border-radius: 0px;
        margin-left: auto;
        margin-right: auto;
    }
    .homepagehubits-courses-learners-banner2-cards1-h1 {
        font-size: 2rem;
        margin: 0;
    }
    
    .homepagehubits-courses-learners-banner2-cards1-p1 {
        font-size: 1.1rem;
        margin-top: 6px;
    }
    .homepagehubits-courses-learners-banner2-cards-image {
        width: 300px;
        height: auto;
        max-width: 400px;
        border-radius: 0px;
        margin-left: 0px;
    }

    .homepage-banner5-cards {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; 
        padding: 10px 10px ;
        gap: 10px; /* Reduce gap */
      }
    
      .homepage-banner5-cards-card1 {
        width: 100%; /* Full width */
        height: auto; /* Adjust height */
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      .homepage-banner5-cards-card1-img {
        width: 80px; /* Reduce size */
        height: 80px;
        padding: 15px;
      }
    
      .homepage-banner5-cards-card1-icons1,
      .homepage-banner5-cards-card1-icons2,
      .homepage-banner5-cards-card1-icons3,
      .homepage-banner5-cards-card1-icons4,
      .homepage-banner5-cards-card1-icons5,
      .homepage-banner5-cards-card1-icons6,
      .homepage-banner5-cards-card1-icons7 {
        width: 50px; /* Smaller icons */
        height: 50px;
      }
    
      .homepage-banner5-cards-card1 h4 {
        font-size: 0.9rem; /* Reduce font size */
        margin-left: 0px;
        text-align: center;
      }
    
      /* Adjust tooltip text on hover */
      .homepage-banner5-cards-card1-img .homepage-banner5-cards-card1-p {
        width: 180px;
        height: 220px;
        font-size: 0.8rem;
        padding: 8px;
        margin-top: 80px;
      }
    
      .dotted-hr {
        width: 0%;
        margin-right: 0px; 
        margin-bottom: -0px; 
      }
    .homepage-banner5-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0px 0px 0px;
    }
    /* organizations */
    .homepage-banner6-card{
        padding-top: 20px;
        gap: 40px;
    }
    .homepage-banner6-card1,
    .homepage-banner6-card2{
        gap: 40px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .homepage-banner6-card-img{
        inline-size: 300px;
        block-size: auto;
        border-radius: 0px;
        margin-top: 10px;
    }
    .homepage-banner8-card1-p {
        font-size: 1.1rem;
    }
    .homepage-banner8-card1-btn {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .homepage-banner8-card2-image {
        width: 300px;
        height: auto;
        border-radius: 0px;
        margin-left: 10px;
    }
    .homepage-banner9-h1{
        text-align: center;
        margin: 0px 20px;
    }
    .homepage-banner9-p{
        font-size: 1rem;
        margin: 0px 20px;
      }
}


/* Tablet Styling */

/* @media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
    .homepagecolores {
        inline-size: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        block-size: 91%;
    }
    .homepage-banner1 {
        height: auto;
        padding-bottom: 20px;
        position: relative; 
        padding: 20px 20px;
    }
    .homepage-banner1 ul {
        padding: 0px 20px 60px 20px;
    }
    .homepage-banner1-spark-h1 {
        width: auto;
        font-size: 40px;
        padding-top: 80px;
    }
    .homepage-banner1-spark-p {
        width: auto;
        font-size: 1.2rem;
    }
    .homepage-banner1-image {
        opacity: 0.3;
    }
    .homepage-banner2,
    .homepage-banner3,
    .homepage-banner5,
    .homepage-banner6,
    .homepage-banner7,
    .homepage-banner8{
        padding: 40px 20px;
    }
    .homepage-banner2-h1{
        font-size: 28px;
    }
    .homepage-banner3-h1,
    .homepage-banner5-h1,
    .homepage-banner6-h1,
    .homepage-banner7-h1,
    .homepage-banner9-h1{
      font-size: 28px;
      line-height: 1.6;
    
    }
    .homepage-banner2-h1 span,
    .homepage-banner3-h1 span,
    .homepage-banner5-h1 span,
    .homepage-banner6-h1 span,
    .homepage-banner7-h1 span{
        margin-left: 0px;
    }
    .homepage-banner2-h1{
        height: 70px;
    }
    .homepage-banner3-p {
        padding: 0px 28px;
    }
    .homepage-banner5-p{
        padding: 10px 0px 0px 0px;
    }
    .homepage-banner2-card1{
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    .homepagehubits-courses-learners-banner2 {
        justify-content:center;
        gap: 60px;
    }
    .homepage-banner5-cards-card1-img .homepage-banner5-cards-card1-p{
        width: 220px;
        height: auto;
        margin: 60px 10px 20px 10px;
    }
    .dotted-hr {
        width: 0px;
        margin-bottom: -0px;
        margin-right: 0px;
    }
    .homepage-banner6-card{
        gap: 40px;
    }
    .homepage-banner8-cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .homepage-banner8-card2 {
        flex: 1;
        max-width: 100%;
      }
      
      .homepage-banner8-card2-image {
        width: auto;
        height: auto;
    }
}