.vertical-progress-container {
    background-color: #97bbdf;
    text-align: center;
    padding-top: 4px;
    height: 200px;
    border-radius: 20px;
    width: 260px;
    margin-left: auto;
    margin-right: auto;
}

.vertical-progress-heading {
    margin-top: 25px;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
    color: #052659;
}

.learningpath-progress-project-bar {
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    height: 60px;
    margin-top: 20px;
}

.projectsprogress-color {
    font-weight: 700;
}

@media (min-width: 320px) and (max-width: 480px) {
    .vertical-progress-container {
        height: 200px;
        width: 250px;
        margin: 20px;
    }
    .vertical-progress-heading {
        margin-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
    }
    .projectsprogress {
        width: 150px;
        height: 100px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px) {
    .vertical-progress-container {
        width: 220px;
        height: 200px;
    }
    .projectsprogress {
        width: 120px;
        height: 45px;
        margin-top: 0px;
    }
    .vertical-progress-heading {
        font-size: 16px;
    }
}