.learners-iot-banner1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    background: linear-gradient(180deg,#252525 20%,#1c84e4 100%);
    padding: 0px 40px 40px 40px;
    margin: 0px 10px 0px 10px;
}
.learners-iot-banner1-ul{
    list-style: none;
}
.learners-iot-banner1-h1{
    font-size: 48px;
    font-weight: 600;
    line-height: 4.5rem;
    color: #ffffff;
    width: 75%;
    padding-top: 20px;
}

.learners-iot-banner1-p1{
    color: rgb(255, 255, 255);
    text-align: start;
    font-size: 1.2rem;
    font-weight: 200;
    padding: 10px 6px;
}
.learners-iot-banner1-image{
    width: 400px;
    height: 400px;
}
.learners-iot-banner1-p3 span {
    font-size: 30px;
    /* color: #ffee00; */
    color: #c4921b;
    padding: 10px 6px 0px 0px;
}
.learners-iot-banner1-p2,
.learners-iot-banner1-p3{
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 1.6;
    color: #ffffff;
    padding: 0px 6px;
}

/* banner2 */

.learners-iot-banner2 {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    height: 128px;
    padding: 6px 10px;
    margin: -35px auto 40px auto;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid rgb(59, 186, 255);
}

.learners-iot-banner2-ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    /* gap: 10px; */
    margin-right: 20px;
}

.learners-iot-banner2-ul-li1,
.learners-iot-banner2-ul-li2,
.learners-iot-banner2-ul-li3,
.learners-iot-banner2-ul-li4{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
.learners-iot-banner2-ul-p1{
    width: 150px;
    font-size: 16px;
    text-align: left;
    color: #484848;
}
.learners-iot-banner2-ul-p3{
    width: auto;
    font-size: 16px;
    text-align: left;
    color: #484848;
}
.learners-iot-banner2-ul-p2{
    width: 180px;
    font-size: 16px;
    line-height: 1.6rem;
    text-align: left;
    color: #484848;
}
.learners-iot-banner2-p3-icon{
    font-size: 40px;
    color: #1c84e4;
}

.learners-iot-banner2-p1-icon{
    font-size: 48px;
    color: #1c84e4;
}

.learners-iot-banner2-p2-icon{
    font-size: 52px;
    color: #1c84e4;
    margin-top: -2px;
}
.learners-iot-banner2-p3-icon{
    font-size: 52px;
    color: #1c84e4;
    margin-top: -2px;
}
.learners-iot-banner2-p4-icon{
    font-size: 50px;
    color: #1c84e4;
    margin-top: 0px;
}
.learners-iot-banner2-li1-seates,
.learners-iot-banner2-li2-seates{
    background-color: #1c84e4;
    font-size: 0.9rem;
    padding: 4px;
    color: #ffff;
    text-align: center;
}
.learners-iot-banner2-li1-seates{
    width: 120px;
}
.learners-iot-banner2-li2-seates{
    width: 130px;
}
.learners-iot-banner2-li1-seates{
    margin-top: -5px;
}

.learners-iot-banner2-li2-seates{
    margin-top: -7px;
}

/* 📌 Pricing */
.learners-iot-banner2-ul-p3-price {
    font-size: 1rem;
    font-weight:bold;
    color: #013770;
}

.learners-iot-banner2-ul-p3-old-price-span {
    margin-top: -14px;
}

.learners-iot-banner2-ul-p3-old-price {
    font-size: 16px;
    text-decoration: line-through;
    color: #ff0000;
}

.learners-iot-banner2-ul-p3-old-price-span .span {
    font-size: 1rem;
    color: #013770;
    font-weight: 400;
}

.learners-iot-banner2-p5-icon{
    font-size: 40px;
    padding: 10px;
}
.learners-iot-banner2-ul-li4-p,
.learners-iot-banner2-ul-li4-p1,
.learners-iot-banner2-ul-li5-p{
    font-size: 1rem;
    text-align: left;
    color: #161616;
}
.learners-iot-banner2-ul-li4-p1{
    margin-top: -12px;
}
/* .learners-iot-banner2-ul-li4-p{
    margin-top: 10px;
} */

/* 📌 Button */
/* Container for button and arrows */
.learners-iot-banner2-button {
  /* background: linear-gradient(135deg, #295dcc, #1e3c72); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 240px;
  height: 65px;
  padding: 10px;
  margin-top: 25px;
  border: 1px solid rgb(14, 104, 249);
  border-radius: 10px;
  position: relative;
}

.learners-iot-banner2-button a {
  text-decoration: none;
}

/* Enroll Now Button */
.learners-iot-banner2-btn {
  background: rgb(14, 104, 249);
  color: white;
  font-weight: 700;
  padding: 0.8rem 1rem;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  backdrop-filter: blur(5px);
  text-decoration: none;
  position: relative;
}

/* Hover effect */
.learners-iot-banner2-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Move animation when clicked */
.move-right {
  animation: moveToArrow 0.6s linear forwards;
}

@keyframes moveToArrow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(80px); /* Adjust as needed */
  }
}
/* Arrow animations */
.arrow-container {
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.arrow-container span {
  color: rgb(14, 104, 249);
  font-size: 1.4rem;
  opacity: 0.8;
  margin-left: 5px;
  animation: moveArrows 1.5s infinite ease-in-out;
}

.arrow-container span:nth-child(2) {
  animation-delay: 0.2s;
}

.arrow-container span:nth-child(3) {
  animation-delay: 0.4s;
}

.arrow-container span:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes moveArrows {
  0%, 100% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(5px);
  }
}

@media (min-width: 320px) and (max-width: 480px) {
    .learners-iot-banner1{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 0px;
        margin: 0px;
        padding: 20px 10px 40px 0px;
    }
    .learners-iot-banner1-h1{
        font-size: 1.6rem;
        line-height: 1.6;
        width: 100%;
        padding-right: 20px;
    }
    
    .learners-iot-banner1-p1{
        padding: 0px;
        font-size: 1rem;
        line-height: 1.6;
    }
    .learners-iot-banner1-p3 span,
    .learners-iot-banner1-p2 {
        font-size: 1rem;
        padding: 0px;
    }
    .learners-iot-banner1-image{
        width: 300px;
        height: 300px;
    }
    .learners-iot-banner2 {
        flex-direction: column;
        gap: 10px;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding: 10px 10px;
        margin: -20px auto 40px auto;
        border-bottom: 0px solid rgb(59, 186, 255);
    }
    .learners-iot-banner2-ul {
        gap: 10px;
        width: 100%;
        justify-content: flex-start;

    }
    .learners-iot-banner2-ul-p1,
    .learners-iot-banner2-ul-p3,
    .learners-iot-banner2-ul-p2{
        width: auto;
        font-size: 1rem;
        text-align: left;
    }
    .learners-iot-banner2-p2-icon{
        margin-top: -10px;
    }
    .learners-iot-banners2-button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    .learners-iot-banner2-button {
      display: flex;
      width: 100%; 
      margin: 10px 28px 20px 0px;
      height: auto;
      padding: 10px;
      text-align: center;
    }
}

/* Tablet Styling */

/* @media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
    .learners-iot-banner1 {
        flex-direction: column; 
        text-align: start;
        padding: 20px 40px; 
        height: auto; 
      }
    
      .learners-iot-banner1-h1 {
        font-size: 36px; 
        line-height: 3.5rem; 
        width: 100%; 
      }
    
      .learners-iot-banner1-p1,
      .learners-iot-banner1-p2,
      .learners-iot-banner1-p3 {
        font-size: 1rem; 
        text-align: start; 
      }
    
      .learners-iot-banner1-image {
        width: 300px;
        height: 300px;
        margin-top: 20px; 
      }
      .learners-iot-banner2 {
        display: flex;
        flex-direction: column; 
        height: auto; 
        padding: 20px 15px; 
        margin: 0 auto; 
        gap: 20px; 
        background-color: #f8f9fa; 
        border-bottom: 2px solid #1c84e4;
      }
    
      .learners-iot-banner2-ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
        gap: 20px; 
        padding: 0;
        width: 100%;
      }
    
      .learners-iot-banner2-ul-li1,
      .learners-iot-banner2-ul-li2,
      .learners-iot-banner2-ul-li3,
      .learners-iot-banner2-ul-li4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        background: #ffffff; 
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
        transition: transform 0.3s ease-in-out;
      }
    
      .learners-iot-banner2-ul-li1:hover,
      .learners-iot-banner2-ul-li2:hover,
      .learners-iot-banner2-ul-li3:hover,
      .learners-iot-banner2-ul-li4:hover {
        transform: scale(1.05);
      }
    
      
      .learners-iot-banner2-ul-p1,
      .learners-iot-banner2-ul-p2,
      .learners-iot-banner2-ul-p3 {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: #333;
      }
    
      
      .learners-iot-banner2-p1-icon,
      .learners-iot-banner2-p2-icon,
      .learners-iot-banner2-p3-icon,
      .learners-iot-banner2-p4-icon {
        font-size: 42px; 
        color: #1c84e4;
      }
    
      
      .learners-iot-banner2-li1-seates,
      .learners-iot-banner2-li2-seates {
        background: #1c84e4;
        font-size: 0.9rem;
        padding: 6px 10px;
        border-radius: 5px;
        color: #fff;
      }
    
      .learners-iot-banner2-button {
        text-align: center;
        width: auto;
        padding: 20px;
      }
    
      .learners-iot-banner2-btn {
        background: #013770;
        color: #ffffff;
        padding: 10px 10px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
    
      .learners-iot-banner2-btn:hover {
        background: #0056b3;
        transform: scale(1.05);
      }
}