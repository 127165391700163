.learners-datascience-banner1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: linear-gradient(180deg,#252525 20%,#1c84e4 100%);
    padding: 20px 40px 40px 40px;
    margin: 0px 10px 0px 10px;
}

.learners-dataScience-banner2 {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    text-align: center;
    width: 92%;
    height: 128px;
    padding: 6px;
    margin: -35px auto 40px auto;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.12);
}

.learners-iot-banner3,
.learners-dataScience-banner3,
.learners-iot-banner4,
.learners-iot-banner5,
.learners-iot-banner6,
.learners-iot-banner7,
.learners-iot-banner8{
    justify-content: center;
     align-items: center;
     padding: 10px 80px 40px 80px;
}

.learners-iot-banner4,
.learners-iot-banner5,
.learners-iot-banner8{
    background-color: #ffff;
}

.learners-iot-banner3-h1,
.learners-iot-banner4-h1,
.learners-data-science-banner4-h1,
.learners-iot-banner5-h1,
.learners-iot-banner6-h1,
.learners-iot-banner7-h1,
.learners-iot-banner8-h1 {
    color: #052659;
    font-size: 27px;
    font-weight:600;
    border-left: 14px solid #1c84e4;
    padding: 0px 0px 0px 16px;
}
.learners-iot-banner9-h1{
    color: #052659;
    font-size: 27px;
    font-weight:600;
    text-align: center;
}

.learners-iot-banner3-h1 span,
.learners-iot-banner4-h1 span,
.learners-data-science-banner4-h1 span,
.learners-iot-banner5-h1 span,
.learners-iot-banner6-h1 span,
.learners-iot-banner7-h1 span,
.learners-iot-banner8-h1 span{
    padding-left: 0px;
}
.learners-iot-banner3-p,
.learners-iot-banner4-p,
.learners-data-science-banner4-p,
.learners-iot-banner5-p{
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 400;
    color: rgb(44, 44, 44);
    padding: 10px 20px 0px 0px;
}
.learners-iot-banner5-p{
    margin-left: 31px;
    line-height: 1.8;
    padding-top: 0px;
}
.learners-iot-banner3{
    background-color: #ffff;
    margin-top: -40px;
    padding-top: 40px;
}
.learners-iot-banner3-cards,
.learners-embedded-systems-banner3-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
    color: #052659;
    gap: 4px;
    padding: 20px 0px;
}

.learners-iot-banner3-cards p:hover,
.learners-embedded-systems-banner3-cards p:hover{
    background-color: #1c84e4;
    color: #ffffff;
    border: 1px solid #3c3b3b;
}

.learners-iot-banner3-cards p{
    background-color: #ffff;
    width: 216px;
    height: 120px;
    padding: 20px;
    font-size: 1.1rem;
    border: 1px solid #1c84e4;
    font-weight: 400;
}

.learners-embedded-systems-banner3-cards p{
    background-color: #ffff;
    width: 220px;
    height: 150px;
    padding: 20px;
    font-size: 1.1rem;
    border: 1px solid #1c84e4;
    font-weight: 400;
}

.learners-iot-banner4-cards {
    display: flex;
    gap: 20px;
    padding: 20px 0px 10px 0px;
}

.learners-iot-banner4-card {
    display: flex;
    align-items: start; 
    text-align: center;
    gap: 20px;
    width: 100%;
    padding: 20px 20px 40px 20px;
}

.learners-iot-banner4-card-h2 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 2rem;
    color: rgb(2, 41, 100);
    width: 100%;
}

.learners-iot-banner4-card-p-span{
    margin-top: -10px;
}
.learners-iot-banner4-card-p{
    font-size: 2rem;
}

.learners-iot-banner4-card-icon {
    font-size: 3.5rem;
    color: #484848;
    align-items: center;
}

.learners-iot-banner4-card-img{
    width: 440px;
    height: 280px;
    margin-top: 20px;
}

.learners-data-science-banner4-cards,
.learners-data-science-banner4-cards1{
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 20px 0px 20px;
    justify-content: center;
    align-items: center;
}

.learners-data-science-banner4-cards1{
    gap: 40px;
}
.learners-data-science-banner4-card{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
}

.learners-data-science-banner4-card1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: auto;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
}
.learners-data-science-banner4-card1:hover {
    transform: scale(1.05);
}
.learners-data-science-banner4-card1-image {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    animation: fadeIn 0.5s ease-in-out, floating 3s ease-in-out infinite;
}

/* Optional: Continuous subtle animation */
@keyframes floating {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}

.learners-data-science-banner4-card-p-span {
    font-size: 1.1rem;
    color: #1c84e4;
    font-weight: 500;
    line-height: 1.5;
    padding: 10px;
}

.learners-data-science-banner4-card-img{
    width: 460px;
    height: 300px;
}

/* .learners-data-science-banner4-cards1-card1{
    width: 220px;
    height: auto;
    text-align: center;
} */

@keyframes pulseEffect {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes floatingEffect {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-8px);
    }
    100% {
        transform: translateY(0px);
    }
}

.learners-data-science-banner4-cards1-card1 {
    width: 250px;
    height: 200px;
    background: #ffffff;
    text-align: center;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: floatingEffect 3s ease-in-out infinite;
}

/* On hover, apply pulse animation */
.learners-data-science-banner4-cards1-card1:hover {
    animation: pulseEffect 0.6s ease-in-out infinite;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.learners-data-science-banner4-cards1-card-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 10px;
}

.learners-data-science-banner4-cards1-card-p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 5px 0;
}

.learners-data-science-banner4-cards1-card-p-span {
    font-size: 16px;
    color: #666;
    font-weight: 600;
    line-height: 1.4;
}

.learners-iot-banner5-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 10px;
    padding: 20px 0px;
}

.learners-iot-banner5-li {
    font-size: 1.1rem;
    font-weight: 700;
    color: rgb(1, 34, 67);
    padding: 10px;
}

.learners-iot-banner5-iot-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #1c84e4;
    /* box-shadow: 0 0px 40px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5); */
}
.learners-iot-banner5-iot-image:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}

.learners-iot-banner5-iot-img{
    width: 75px;
    height: 55px;
}

.learners-iot-banner5-li-p {
    width: 200px;
    height: 180px;
    text-align: center;
}

.learners-iot-banner5-p1 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding: 20px;
}

.learners-iot-banner6-cards{
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 40px 40px 60px 40px;
    gap: 80px;
}

.learners-iot-banner6-card-1{
    width: auto;
    height: auto;
}

.learners-iot-banner6-card-1-p1 {
    background-color: #ffffff;
    border-left: 4px solid #007bff; 
    font-size: 1.2rem;
    font-weight: 400;
    text-align: start;
    align-items: center;
    padding: 0px 10px;
    margin: 20px 0px;
}
/* .learners-iot-banner6-card-1-pdf {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    gap: 8px; 
    text-align: center; 
}

.learners-iot-banner6-card-1-pdf-button {
    background-color: rgb(14, 104, 249);
    color: rgb(255, 255, 255) !important;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.learners-iot-banner6-card-1-pdf-button:hover {
    background-color: rgb(2, 72, 184);
    transform: scale(1.05);
}

.learners-iot-banner6-card-1-pdf-button-download{
    animation: moveDown 1.5s infinite linear;
    padding-left: 10px;
  }
  @keyframes moveDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(14px);
      opacity: 0;
    }
  }

.learners-iot-banner6-card-1-pdf-button svg {
    font-size: 16px;
} */

.learners-iot-banner6-card-image{
    width: 570px;
    height: 350px;
}

/* .learners-iot-banner6-card-1-pdf-form{
    width: 100%;
    height: 100%;
}
.learners-iot-banner6-card-1-pdf-input{
    margin: 20px 20px 10px 20px;
    padding: 10px;
}
.learners-iot-banner6-card-1-pdf-submit-button{
    align-items: center;
    justify-content: center;
    background: #007bff;
    color: white !important;
    padding: 10px 16px;
    width: 150px;
    border: none;
}
.learners-datascience-banner6-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 60px 5%;
    position: relative;
    overflow: hidden;
}

.learners-datascience-banner6-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 18px;
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 1.5;
    max-width: 500px;
    position: relative;
    z-index: 2;
}

.learners-datascience-banner6-card-1 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
    transition: transform 0.3s ease-in-out;
    text-align: left;
    padding: 8px 0;
}

.learners-datascience-banner6-card-1 p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.learners-datascience-banner6-card-1 span {
    font-size: 2rem; 
}

.learners-datascience-banner6-card-1:hover {
    transform: scale(1.05);
}

.learners-datascience-banner6-card-image {
    width: 100%;
    max-width: 480px;
    border-radius: 20px;
    object-fit: cover;
    transition: transform 0.4s ease-in-out;
}

.learners-datascience-banner6-card-image:hover {
    transform: scale(1.05) rotate(2deg);
}

@keyframes floating {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-8px); }
    100% { transform: translateY(0px); }
}

.learners-datascience-banner6-card-image {
    animation: floating 5s ease-in-out infinite;
} */

.learners-iot-tech-talent-banner7-cards {
    display: flex;
    align-items: center;
    justify-content:space-between;
    gap: 10px;
    margin: 40px auto;
    width: 100%;
}

.learners-iot-tech-talent-banner7-1,
.learners-iot-tech-talent-banner7-card-1,
.learners-embedded-tech-talent-banner7-card-1 {
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    background-color: #ffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.learners-iot-tech-talent-banner7-1{
    width: 50%;
    height: 300px;
}
.learners-iot-tech-talent-banner7-card-1 {
    width: 46%;
    height: 320px;
}

.learners-embedded-tech-talent-banner7-card-1{
    width: 50%;
    height: 445px;
}

.learners-iot-tech-talent-banner7-card-protocols{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.learners-iot-tech-talent-banner7-1:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.learners-iot-tech-talent-banner7-1 h2,
.learners-iot-tech-talent-banner7-card-1 h2,
.learners-embedded-tech-talent-banner7-card-1 h2 {
    font-size: 1.2rem;
    color: rgb(2, 41, 100);
    line-height: 1.6;
}

.learners-embedded-tech-talent-banner7-cards-1{
    padding: 0px 10px;
}
/* @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
} */

.learners-iot-tech-talent-banner7-logos {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 10px;
    /* animation: pulse 2s infinite ease-in-out; */
}

.learners-iot-tech-talent-banner7-logos-iot{
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin: 6px 20px;
}

.learners-datascience-tech-talent-banner7-logos{
    width: 90px;
    height: 90px;
    object-fit: contain;
    margin: 10px;
    animation: pulse 2s infinite ease-in-out;
}

.learners-iot-banner8-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px 0px 10px 30px;
}

.learners-iot-banner8-botton,
.learners-iot-banner8-botton1 {
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* Default States */
.learners-iot-banner8-botton {
    background-color: transparent;
    color: #000000;
    border: 2px solid #ccc;
    box-shadow: none;
}

/* Hover Effects */
.learners-iot-banner8-botton:hover{
    transform: translateY(-2px);
}

/* Active (Clicked) State - Removes Background */
.learners-iot-banner8-botton.active {
    background-color: #007bff;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
}

.learners-iot-banner9{
    background-color: #ffff;
    padding: 10px 0px;
}

.learners-iot-banner10-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.learners-iot-banner10-btn{
    background-color: rgb(14, 104, 249);
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 10px 20px;
    width: 250px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.learners-iot-banner10-btn:hover {
    background-color: rgb(2, 72, 184);
    transform: scale(1.05);
}

@media (min-width: 320px) and (max-width: 480px) {
    .learners-datascience-banner1{
        flex-direction: column;
        height: auto;
        padding: 0px;
        margin: 0px;
        padding: 20px 0px 40px 0px;
    }
    
    .learners-dataScience-banner2 {
        flex-direction: column;
        gap: 10px;
        text-align: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding: 10px 10px;
        margin: -20px auto 40px auto;
        box-shadow: none;
        /* border-bottom: 1px solid rgb(59, 186, 255); */
    }
.learners-iot-banner3,
.learners-dataScience-banner3,
.learners-iot-banner4,
.learners-iot-banner5,
.learners-iot-banner6,
.learners-iot-banner7,
.learners-iot-banner8{
    padding: 20px;
}
.learners-iot-banner3-h1,
.learners-iot-banner4-h1,
.learners-data-science-banner4-h1,
.learners-iot-banner5-h1,
.learners-iot-banner6-h1,
.learners-iot-banner7-h1,
.learners-iot-banner8-h1 {
    font-size: 1.4rem;
    text-align: left;
    border-left: 0px solid rgb(27, 110, 244);
    padding: 0px 10px;
    line-height: 1.5;
}
.learners-iot-banner3-p,
.learners-iot-banner4-p,
.learners-data-science-banner4-p{
    text-align: left;
    font-size: 1rem;
    padding: 0px 4px;
}
.learners-iot-banner5-p{
    text-align: left;
    font-size: 1rem;
    width: 100%;
    margin-left: 0px;
    padding-left: 10px;
}
.learners-iot-banner3-cards,
.learners-embedded-systems-banner3-cards{
    justify-content:center;
}
.learners-iot-banner3-cards p,
.learners-embedded-systems-banner3-cards p{
    width: 280px;
    height: auto;
}
.learners-iot-banner4-cards,
.learners-data-science-banner4-cards,
.learners-data-science-banner4-cards1 {
    flex-direction: column;
    padding: 10px 0px 10px 0px;
}
.learners-data-science-banner4-cards1{
    gap: 20px;
}
.learners-iot-banner4-card,
.learners-data-science-banner4-card,
.learners-data-science-banner4-card1 {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}
.learners-iot-banner4-card-h2 {
    font-size: 1.2rem;
}
.learners-iot-banner4-card-img,
.learners-data-science-banner4-card-img{
    width: 320px;
    height: auto;
    padding-left: 10px;
}
.learners-iot-banner5-ul {
    gap: 10px;
    padding: 20px 0px;
}
.learners-iot-banner5-li-p {
    width: auto;
}
.learners-iot-banner6-cards,
.learners-datascience-banner6-card{
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}
.learners-iot-banner6-card-1,
.learners-datascience-banner6-card-1{
    width: auto;
    height: auto;
}
.learners-iot-banner6-card-image,
.learners-datascience-banner6-card-image{
    width: 320px;
    height: auto;
}
.learners-iot-banner6-card-1-pdf-submit-button{
    border-radius: 0px;
}
.learners-iot-tech-talent-banner7-cards {
    flex-direction: column;
    margin: 20px auto;
    width: 100%;
}
.learners-iot-tech-talent-banner7-1,
.learners-iot-tech-talent-banner7-card-1,
.learners-embedded-tech-talent-banner7-card-1,
.learners-embedded-tech-talent-banner7-card-1  {
    padding: 20px;
    border-radius: 0px;
    width: 100%;
    height: auto;
}
.learners-iot-banner8-btn {
    padding: 20px;
}
.learners-iot-banner10-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 40px 0px 40px 20px;
}
}

/* Tablet Styling */

/* @media (min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
    .learners-datascience-banner1{
        flex-direction: column;
        height: auto;
        padding: 20px;
    }
    
    .learners-dataScience-banner2 {
        display: flex;
        flex-direction: column; 
        height: auto; 
        padding: 20px 15px; 
        margin: 0 auto; 
        gap: 20px; 
        background-color: #f8f9fa; 
        border-bottom: 2px solid #1c84e4;
    }
    .learners-iot-banner3,
    .learners-dataScience-banner3,
    .learners-iot-banner4,
    .learners-iot-banner5,
    .learners-iot-banner6,
    .learners-iot-banner7,
    .learners-iot-banner8{
     padding: 40px 20px 20px 20px;
}
    .learners-iot-banner3-h1,
    .learners-iot-banner4-h1,
    .learners-data-science-banner4-h1,
    .learners-iot-banner5-h1,
    .learners-iot-banner6-h1,
    .learners-iot-banner7-h1,
    .learners-iot-banner8-h1 {
        padding: 0px 0px 0px 10px;
        line-height: 1.4;
    }
    .learners-iot-banner4-cards {
        flex-direction: column; 
        gap: 20px;
      }
    
      .learners-iot-banner4-card {
        align-items: center;
        justify-content: center;
        padding: 20px; 
      }
    
      .learners-iot-banner4-card-h2 {
        font-size: 1rem; 
        line-height: 1.8rem;
        width: 100%;
      }
    
      .learners-iot-banner4-card-p {
        font-size: 1.6rem; 
      }
    
      .learners-iot-banner4-card-icon {
        font-size: 3rem; 
      }
    
      .learners-iot-banner4-card-img {
        width: 100%; 
        max-width: 400px; 
        height: auto; 
        margin-top: 10px;
        display: block;
        margin-left: auto; 
        margin-right: auto; 
    }
    .learners-iot-banner6-cards{
        flex-wrap: wrap;
        padding: 40px 0px 20px 0px;
        gap: 40px;
    }
    .learners-iot-banner6-card-1-pdf {
        flex-direction: column; 
        align-items: center; 
        justify-content: center; 
        gap: 10px; 
        text-align: center; 
    }
    .learners-iot-tech-talent-banner7-cards {
        display: flex;
        gap: 10px;
        margin: 40px auto;
        width: 100%;
    }
    
    .learners-iot-tech-talent-banner7-1,
    .learners-embedded-tech-talent-banner7-card-1{
        width: 50%;
        height: auto;
    }
    .learners-iot-tech-talent-banner7-card-1 {
        width: 46%;
        height: auto;
    }
}





    

