.mylearningpaths {
    width: 100%;
    height: 89vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    box-sizing: border-box;
}
.mylearningpaths-h1,
.mylearningpaths-p {
    font-weight: 600;
}

.mylearningpaths-h1 {
    padding: 14px 0px 0px 45px;
    font-size: 30px;
}

.mylearningpaths-p {
    font-size: 1.4rem;
    font-weight: 700;
    padding: 10px 0px 0px 45px;
    color: #052659;
}

.custom-progress-bar .progress-bar {
    background-color: #052659;
}

.mylearningpath {
    display: flex;
    margin-left: 14px;
}

.pythoncourses1,
.pythoncourses2,
.pythoncourses3,
.pythoncourses4,
.pythoncourses5,
.pythoncourses6,
.pythoncourses7,
.pythoncourses8,
.pythoncourses9,
.pythoncourses10,
.pythoncourses11,
.pythoncourses12,
.pythoncourses13,
.pythoncourses14,
.pythoncourses15,
.pythoncourses16,
.pythoncourses17,
.pythoncourses18,
.pythoncourses19,
.pythoncourses20 {
    font-weight: 700;
    line-height: 1.5rem;
    font-size: 1.4rem;
    padding: 10px 10px 0px 4px;
}

.learningpath-courses-dashboard {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #5483B3;
    color: black;
    margin-bottom: 20px;
    width: 950px;
    border-radius: 10px;
    height: 160px;
    padding: 0px 40px 0px 0px;
}

.learningpath-courses-dashboard-skills {
    font-weight: 400;
    line-height: 1rem;
    font-size: 1.2rem;
    padding-top: 6px;
}

.learningpath-courses-dashboard-skills-span {
    font-size: 1rem;
}

.learningpath-courses-dashboard-progressbar {
    height: 10px;
    margin-bottom: 10px;
    line-height: 1rem;
}

.learningpath-courses-dashboard-progress-bar {
    padding-bottom: 10px;
}

.learningpath-courses-dashboard-skills-completed {
    line-height: 0.1rem;
    font-size: 1.4rem;
    margin-left: 650px;
    margin-bottom: 14px;
}

.custom-progress-bar {
    color: #052659 !important;
}

.learningpath-courses-dashboard-button-start {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: 0px solid;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: auto;
    height: auto;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: initial;
    margin-top: 30px;
    padding: 0px 10px 4px 10px;
    align-items: end;
    text-align: right;
    display: flex;
    margin-left: auto;
}

.learningpath-courses-dashboard-button-start:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
    transform: scale(1.1) translateY(-5px);
}

.Circle-sc-fvyqwl-1 {
    list-style: none;
}

.mypython {
    font-size: 20px;
}

.smart-shoe-development {
    width: 220px;
    height: 160px;
    margin-right: 10px;
    border-radius: 10px;
}

@media (max-width: 480px) {
    .mylearningpaths {
        height: 84vh;
    }
    .mylearningpaths-h1 {
        padding: 40px 0px 0px 20px;
        font-size: 20px;
    }
    .mylearningpaths-p {
        padding: 10px 0px 0px 20px;
    }
    .mylearningpath {
        flex-direction: column;
        margin-left: 0px;
    }
    .pythoncourses1,
    .pythoncourses2,
    .pythoncourses3,
    .pythoncourses4,
    .pythoncourses5,
    .pythoncourses6,
    .pythoncourses7,
    .pythoncourses8,
    .pythoncourses9,
    .pythoncourses10,
    .pythoncourses11,
    .pythoncourses12,
    .pythoncourses13,
    .pythoncourses14,
    .pythoncourses15,
    .pythoncourses16,
    .pythoncourses17,
    .pythoncourses18,
    .pythoncourses19,
    .pythoncourses20 {
        line-height: 1.5rem;
        padding: 10px 0px 0px 10px;
    }
    .learningpath-courses-dashboard {
        flex-direction: column;
        width: 296px;
        height: auto;
        margin: 20px 30px 20px 0px;
        padding-bottom: 20px;
    }
    .learningpath-courses-dashboard-skills {
        line-height: 1.5rem;
        font-size: 1rem;
        padding: 0px 0px 0px 10px;
    }
    .learningpath-courses-dashboard-progressbar {
        height: 10px;
        margin-bottom: 10px;
        line-height: 1rem;
        padding: 0px 0px 0px 10px;
    }
    .learningpath-courses-dashboard-progress-bar {
        padding-bottom: 10px;
    }
    .learningpath-courses-dashboard-skills-completed {
        line-height: 0.1rem;
        font-size: 1.4rem;
        margin-left: 10px;
        margin-bottom: 14px;
    }
    .learningpath-courses-dashboard-button-start {
        width: auto;
        height: auto;
        margin-left: 10px;
    }
    .mypython {
        font-size: 20px;
    }
    .smart-shoe-development {
        width: 296px;
        height: 150px;
        border-radius: 10px;
        margin-bottom: 10px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .mylearningpaths {
        height: 86vh;
        /* padding: 0px 28px 10px 0px; */
    }
    .mylearningpaths-h1 {
        padding: 14px 0px 0px 20px;
        font-size: 30px;
    }
    .mylearningpaths-p {
        font-size: 1.4rem;
        padding: 20px 0px 0px 20px;
    }
    .mylearningpath {
        margin-left: 0px;
    }
    .pythoncourses1,
    .pythoncourses2,
    .pythoncourses3,
    .pythoncourses4,
    .pythoncourses5,
    .pythoncourses6,
    .pythoncourses7,
    .pythoncourses8,
    .pythoncourses9,
    .pythoncourses10,
    .pythoncourses11,
    .pythoncourses12,
    .pythoncourses13,
    .pythoncourses14,
    .pythoncourses15,
    .pythoncourses16,
    .pythoncourses17,
    .pythoncourses18,
    .pythoncourses19,
    .pythoncourses20 {
        line-height: 1.2rem;
        font-size: 1.2rem;
    }
    .learningpath-courses-dashboard {
        width: auto;
        height: auto;
    }
    .learningpath-courses-dashboard-skills-completed {
        margin-left: auto;
    }
    .learningpath-courses-dashboard-button-start {
        margin-top: 25px;
        margin-left: auto;
    }
    .mypython {
        font-size: 20px;
    }
    .custom-progress-bar {
        width: 400px;
    }
    .smart-shoe-development {
        width: 250px;
        height: auto;
        margin-right: 10px;
    }
}