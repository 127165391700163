.organization-data-science-tech-talent-banners1,
.organization-iot-tech-talent-banner4,
.organization-iot-tech-talent-banner6,
.organization-iot-tech-talent-banner7 {
  background-size: cover;
  background-position: center;
  flex-wrap: wrap;
  position: relative;
  height: 90vh;
  color: #fff;
}

.organization-iot-tech-talent-banners1{
  background-image: url('../../assets/companiesimages/pages/3.png'); 
}
.organization-data-tech-talent-banners1{
  background-image: url('../../assets/companiesimages/pages/4.png'); 
}
.organization-embedded-tech-talent-banners1{
  background-image: url('../../assets/companiesimages/pages/5.png'); 
}
  .organization-iot-tech-talent-banners1,
  .organization-data-tech-talent-banners1,
  .organization-embedded-tech-talent-banners1 {
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    width: 100%; 
    height: 560px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 60px;
}
.organization-iot-tech-talent-banner1{
  list-style: none;
}
.organization-iot-tech-talent-banner1 h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  color: #ffffff;
}
/* .organization-iot-tech-talent-banner1 h1 span{
  color: rgb(255, 255, 4);
} */
.organization-iot-tech-talent-banner1-image{
  width: 400px;
  height: 400px;
}
.organization-iot-tech-talent-banner1 p{
  color: rgb(255, 255, 255);
    text-align: start;
    font-size: 1.1rem;
    font-weight: 200;
    padding: 10px 6px;
}
.organization-iot-tech-talent-banner2,
.organization-iot-tech-talent-banner3,
.organization-iot-tech-talent-banner4,
.organization-iot-tech-talent-banner5,
.organization-iot-tech-talent-banner6,
.organization-iot-tech-talent-banner7 {
  padding: 40px 80px 40px 80px;
}
.organization-iot-tech-talent-banner2{
  background: linear-gradient(135deg, #f5f8fd, #f2f6fc);
  padding-top: 30px;
}
  .organization-iot-tech-talent-banner2-h1,
  .organization-iot-tech-talent-banner3 h1,
  .organization-iot-tech-talent-banner4-h1,
  .organization-iot-tech-talent-banner5 h1,
  .organization-iot-tech-talent-banner6 h1{
    color: #052659;
    text-align: left;
    font-size: 26px;
    font-weight: 700;
    border-left: 14px solid rgb(27, 110, 244);
    padding: 0px 10px 0px 20px;
  }
  .organization-iot-tech-talent-banner2-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
  }
  
  .organization-iot-tech-talent-banner2-p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8;
    color: #000000;
    text-align: left;
    width: 500px;
  }
  
  .organization-iot-tech-talent-banner2-button {
    display: flex;
    gap: 30px;
  }
  
  .organization-iot-tech-talent-banner2-button button {
    background-color: rgb(27, 110, 244);
    color: white;
    width: auto;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .organization-iot-tech-talent-banner2-button button:hover {
    background-color: rgb(0, 96, 252);
    transform: scale(1.05);
  }
  
  .organization-iot-tech-talent-banner2-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

  .organization-iot-tech-talent-banner3-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    padding-top: 40px;
    margin-bottom: 1.5rem;
  }
  
  .animated-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgb(27, 110, 244);
    color: #043885;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 220px;
    height: 220px;
    animation: slideUp 1s ease-in-out;
  }
  
  .animated-card:hover {
    transform: translateY(-5px);
    box-shadow: none;
  }

  .organization-iot-tech-talent-banner3-card-image1{
    width: 160px;
    height: 100px;
    align-items: center;
  }
  .organization-iot-tech-talent-banner3-card-image{
    width: 100px;
    height: 100px;
    align-items: center;
  }
  .organization-iot-tech-talent-banner3-card-image4{
    width: 140px;
    height: 100px;
    align-items: center;
  }
  .organization-iot-tech-talent-banner3-card-image6{
    width: 160px;
    height: 100px;
    align-items: center;
  }
  .organization-iot-tech-talent-banner3-card p {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }
  .organization-iot-tech-talent-banner4{
    height: auto;
  }
  .organization-iot-tech-talent-banner4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/companiesimages/InternetofThings4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: 0;
}

.organization-iot-tech-talent-banner4 > * {
    position: relative;
    z-index: 1;
}
  .organization-iot-tech-talent-banner4-div1-p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8;
    color: #000000;
    text-align: left;
    width: 100%;
    padding: 10px 0px 0px 32px;
  }
  .organization-iot-tech-talent-banner4-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 40px;
    padding: 40px 20px;
}

.organization-iot-tech-talent-banner4-card > div {
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 15px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    color: #052659;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 350px;
}

.organization-iot-tech-talent-banner4-card > div:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); 
    background-color: rgb(27, 110, 244);
    color: #ffff;
}

.organization-iot-tech-talent-banner4-card p {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.organization-iot-tech-talent-banner5-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  text-align: center;
  /* align-items: center; */
  padding-top: 20px;
}

.organization-iot-tech-talent-banner5-1 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px 10px 10px 40px;
  margin: 10px;
  width: 46%;
  height: 380px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}
.organization-iot-tech-talent-banner5-2 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0px;
  margin: 10px;
  width: 46%;
  height: auto; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}
.organization-iot-tech-talent-banner5-data-1,
.organization-iot-tech-talent-banner5-data-2,
.organization-embedded-tech-talent-banner5-1{
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.organization-iot-tech-talent-banner5-data-1{
  width: 46%;
  height: 520px; 
}

.organization-iot-tech-talent-banner5-card-protocols div{
  padding: 0px 10px;
}
.organization-iot-tech-talent-banner5-data-2{
  width: 100%;
  height: auto; 
}

.organization-embedded-tech-talent-banner5-1{
  width: 46%;
  height: 320px;
}
.organization-iot-tech-talent-banner5-card-protocols{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}
.organization-iot-tech-talent-banners5-1:hover,
.organization-iot-tech-talent-banner5-1:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.organization-iot-tech-talent-banners5-1 h2,
.organization-iot-tech-talent-banner5-1 h2,
.organization-iot-tech-talent-banner5-2 h2,
.organization-iot-tech-talent-banner5-data-1 h2,
.organization-iot-tech-talent-banner5-data-2 h2,
.organization-embedded-tech-talent-banner5-1 h2 {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: #052659;
  padding: 10px;
}

.organization-iot-tech-talent-banner5-logos-iot {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 20px;
  transition: transform 0.3s;
}

.organization-data-tech-talent-banner5-logos{
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 20px;
  transition: transform 0.3s;
}

.organization-embedded-tech-talent-banner5-logos{
  width: 70px;
  height: 70px;
  margin: 20px;
  transition: transform 0.3s;
}

.organization-iot-tech-talent-banner5-logos-iot:hover,
.organization-embedded-tech-talent-banner5-logos:hover {
  transform: scale(1.1);
}

/* embedded-system */
.embedded-system-container5{
  padding: 20px 100px 40px 100px;
}

.embedded-system-container5-content{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 100px;
}
.embedded-system-container5-p{
color: #043885;
font-size: 40px;
font-weight: 700;
text-align: center;
}

.embedded-system-container5-contact{
font-size: 1.2rem;
font-weight: 400;
text-align: center;
color: #000000;
}

.embedded-system-container5-image{
  width: 500px;
  height: auto;
}

.organization-iot-tech-talent-banner6 {
  position: relative;
  block-size: auto;
}

.organization-iot-tech-talent-banner6::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/companiesimages/InternetofThings5.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1; /* Ensures the background image remains fully visible */
}

.organization-iot-tech-talent-banner6::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black overlay with 40% opacity */
  z-index: 1;
}

.organization-iot-tech-talent-banner6 > * {
  position: relative;
  z-index: 2; /* Ensures text stays above the overlay */
}

.organization-iot-tech-talent-banner6 h1{
    color: #ffff;
}
.organization-iot-tech-talent-banner6-p{
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2;
  color: #ffff;
  text-align: left;
  padding: 6px 0px 0px 31px;
}
.organization-iot-tech-talent-banner6-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  gap: 0px;
  margin-top: 60px;
}

.organization-iot-tech-talent-banner6-step {
  text-align: center;
  max-width: 200px;
  margin-right: 10px;
  /* flex: 1 1 calc(33.33% - 10px); Ensures 3 items per row with proper spacing */
}

@keyframes bounce {
  0%, 100% {
    transform: rotate(45deg) translateY(0);
  }
  50% {
    transform: rotate(45deg) translateY(-15px);
  }
}

.organization-iot-tech-talent-banner6-diamond {
  position: relative;
  margin: 40px auto;
  width: 70px;
  height: 70px;
  background-color: #052659;
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 4px solid white;
  transform: rotate(45deg);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: bounce 2s infinite ease-in-out;
}

.organization-iot-tech-talent-banner6-diamond span {
  transform: rotate(-45deg);
  display: block;
}

.organization-iot-tech-talent-banner6-diamond::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translate(-50%, -50%) rotate(-45deg);
  border-radius: 5px;
}

.organization-iot-tech-talent-banner6-step p {
  font-size: 16px;
  font-weight: 900;
  color: #ffff;
  margin-top: 10px;
  text-align: center;
}
.organization-iot-tech-talent-banner7 {
  position: relative;
  block-size: auto;
}

.organization-iot-tech-talent-banner7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/companiesimages/InternetofThings6.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1; /* Ensures the background image remains fully visible */
  z-index: 0;
}

.organization-iot-tech-talent-banner7::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black overlay with 40% opacity */
  z-index: 1;
}

.organization-iot-tech-talent-banner7 > * {
  position: relative;
  z-index: 2; /* Ensures text stays above the overlay */
}

.organization-iot-tech-talent-banner7-h1{
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #ffff;
}
.organization-iot-tech-talent-banner7-p{
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffff;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 76%;
  padding-top: 10px;
}
.organization-iot-tech-talent-banner7-contact {
  text-align: center;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.organization-iot-tech-talent-banner7-contact h1 {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(4, 68, 137, 0.7);
}

.organization-iot-tech-talent-banner7-contact p {
  font-size: 18px;
  color: #ffffff;
}

.organization-iot-tech-talent-banner7-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin: 80px auto;
}

.organization-iot-tech-talent-banner7-btn1 {
  text-align: center;
  flex: 1 1 300px;
  max-width: 300px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2); 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.organization-iot-tech-talent-banner7-btn1:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.organization-iot-tech-talent-banner7-btn1 button {
  background-color: #052659;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.organization-iot-tech-talent-banner7-btn1 button:hover {
  background-color: #043885;
}

.organization-iot-tech-talent-banner7-btn1 p {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
}

/* Icons */
.organization-iot-tech-talent-banner8{
  background-color: #ffff;
  height: auto;
  padding-top: 20px;
}
.organization-iot-tech-talent-banner8-p{
  color: #043885;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.organization-iot-tech-talent-banner8-1{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 140px;
  padding-bottom: 40px;
}
.organization-iot-tech-talent-banner8-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.organization-iot-tech-talent-banner8-cards-image{
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.organization-iot-tech-talent-banner8-cards-Partners{
  width: 500px;
  height: 450px;
  border-radius: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
.organization-iot-tech-talent-banners1,
.organization-data-tech-talent-banners1,
.organization-embedded-tech-talent-banners1{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px 20px 20px 0px;
  margin: 0px;
}
.organization-iot-tech-talent-banner1 h1 {
  font-size: 1.6rem;
  line-height: 1.6;
  width: 100%;
  text-align: start;
}
.organization-iot-tech-talent-banner1-image{
  width: 320px;
  height: auto;
  align-items: center;
}
.organization-iot-tech-talent-banner1 p{
    font-size: 1.1rem;
    text-align: start;
    line-height: 1.8;
    padding: 0px;
}
.organization-iot-tech-talent-banner2,
.organization-iot-tech-talent-banner3,
.organization-iot-tech-talent-banner4,
.organization-iot-tech-talent-banner5,
.organization-iot-tech-talent-banner6,
.organization-iot-tech-talent-banner7,
.embedded-system-container3,
.embedded-system-container5 {
  padding: 20px;
}
.organization-iot-tech-talent-banner2-h1,
.organization-iot-tech-talent-banner3 h1,
.embedded-system-container3 h1,
.organization-iot-tech-talent-banner4-h1,
.organization-iot-tech-talent-banner4-h1,
.organization-iot-tech-talent-banner5 h1,
.organization-iot-tech-talent-banner6 h1,
.organization-iot-tech-talent-banner7-h1,
.organization-iot-tech-talent-banner7-contact h1{
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.6;
  border-left: none;
  padding: 0px 10px;
}
.organization-iot-tech-talent-banner2-card {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 60px;
  gap: 10px;
}
.embedded-system-container2-card {
  width: 100%;
}
.organization-iot-tech-talent-banner2-p,
.organization-iot-tech-talent-banner7-contact p{
  line-height: 1.8;
  text-align: left;
  font-size: 1rem;
  width: 100%;
  padding: 0px;
}
.organization-iot-tech-talent-banner2-button {
  flex-direction: column;
  gap: 30px;
  margin-left: 0px;
}
.organization-iot-tech-talent-banner2-image img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 0px;
  padding-top: 20px;
}
  .organization-iot-tech-talent-banner3-card-image{
    width: 200px;
    height: 150px;
    align-items: center;
  }
  .organization-iot-tech-talent-banner4{
    padding: 40px 20px;
    height: auto;
    margin-top: -20px;
  }
  .organization-iot-tech-talent-banner4-div1-p{
    font-size: 1rem;
    text-align: left;
    width: 100%;
    padding: 0px 10px;
  }
  .organization-iot-tech-talent-banner4-card > div {
    padding: 10px;
}
.organization-iot-tech-talent-banner4-card p {
    font-size: 1.1rem;
    line-height: 2rem;
}

.organization-iot-tech-talent-banner5-1,
.organization-iot-tech-talent-banner5-data-1,
.organization-iot-tech-talent-banner5-data-2,
.organization-embedded-tech-talent-banner5-1,
.organization-iot-tech-talent-banner5-2 {
  margin: 20px;
  width: calc(40% - 20px);
  min-width: 350px;
  height: auto; 
}
  .organization-iot-tech-talent-banner6{
    padding: 40px 20px;
    height: auto;
  }
 
  .organization-iot-tech-talent-banner6 p{
      inline-size: 100%;
      font-size: 1rem;
      margin: 0px 0px 0px -10px;
      padding: 10px 10px 0px -10px;
  }
  
  .organization-iot-tech-talent-banner6-step {
    max-width: 120px;
  }
  .organization-iot-tech-talent-banner6-diamond {
    width: 50px;
    height: 50px;
    gap: 20px;
  }
  .organization-iot-tech-talent-banner7{
    padding: 40px 10px;
    height: auto;
  }
  .organization-iot-tech-talent-banner7-p{
    width: 100%;
    font-size: 1rem;
    text-align: left;
    padding: 0px 10px;
  }
  .organization-iot-tech-talent-banner7-contact {
    margin: 20px auto;
    border-radius: 0px;
    padding: 0px;
  }

  .organization-iot-tech-talent-banner7-contact p{
    padding-left: 10px;
  }
  
  .organization-iot-tech-talent-banner7-btn1 {
    border-radius: 0px;
    margin-top: 10px;
  }
  /* Icons */
.organization-iot-tech-talent-banner8{
  height: auto;
  padding: 20px;
}
.organization-iot-tech-talent-banner8-p{
  font-size: 1.2rem;
}
.organization-iot-tech-talent-banner8-1{
  gap: 20px;
  padding-bottom: 20px;
}
.organization-iot-tech-talent-banner8-cards-image{
  width: 100px;
  height: 100px;
}
.organization-iot-tech-talent-banner8-cards-Partners{
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 0px;
}
.embedded-system-container5-content{
  flex-direction: column;
  padding: 20px;
}
.embedded-system-container5-image{
  width: 300px;
  height: auto;
}
}
/* @media (min-width: 481px) and (max-width: 1024px),
(min-width: 768px) and (max-width: 1024px),
(min-width: 1024px) and (max-width: 1279px) { */
@media (min-width: 481px) and (max-width: 1023px){
  .organization-iot-tech-talent-banners1{
    flex-wrap: wrap;
    height: auto;
    margin: 0px;
    padding: 60px 20px;
  }
  .organization-iot-tech-talent-banner1 h1 {
    width: 100%;
  }
  .organization-iot-tech-talent-banner1-image{
    width: 400px;
    height: 400px;
  }
  .organization-iot-tech-talent-banner1 p{
      font-size: 1.4rem;
      line-height: 2;
      padding: 0px;
  }

  .organization-iot-tech-talent-banner2,
  .organization-iot-tech-talent-banner3,
  .organization-iot-tech-talent-banner4,
  .organization-iot-tech-talent-banner5,
  .organization-iot-tech-talent-banner6,
  .organization-iot-tech-talent-banner7 {
    padding: 20px 30px;
}
  .organization-iot-tech-talent-banner2-h1,
  .organization-iot-tech-talent-banner3 h1,
  .organization-iot-tech-talent-banner4-h1,
  .organization-iot-tech-talent-banner5 h1,
  .organization-iot-tech-talent-banner6 h1{
    padding: 0px 10px;
    line-height: 1.6;
  }
  .organization-iot-tech-talent-banner2 p{
    inline-size: 100%;
    padding-top: 40px;
  }

  .organization-iot-tech-talent-banner2-card {
    flex-wrap: wrap;
    padding-top: -20px;
    gap: 20px;
  }
  .organization-iot-tech-talent-banner2-p{
    width: auto;
    padding: 0px 30px;
  }
  .organization-iot-tech-talent-banner2-btn{
    padding: 0px 30px;
  }
  .organization-iot-tech-talent-banner3-card {
    gap: 20px;
    padding-top: 20px;
  }
  .animated-card {
    width: 200px;
    height: 200px;
  }
    .organization-iot-tech-talent-banner4-div1-p{
      width: 100%;
    }
  
  .organization-iot-tech-talent-banner4-card > div {
      height: 150px;
      width: 300px;
  }
  .organization-iot-tech-talent-banner5-1,
  .organization-iot-tech-talent-banner5-2 {
    width: calc(45% - 10px);
    min-width:auto; 
    height: auto;
  }
}