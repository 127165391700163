/* Further adjustments for smaller screens (14-inch and below) */
@media (min-width: 1024px) and (max-width: 1500px) {
    .homepage-banner1 {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    /* Styles for small laptops */
    .homepage-banner1-spark-h1 {
        font-size: 40px;
    }
    .homepage-banner1-image {
        max-width: 100%;
        height: 400px;
        object-fit: cover;
      }
    .homepage-banner3,
    .homepage-banner5,
    .homepage-banner6,
    .homepage-banner7,
    .homepage-banner8{
        padding-left: 60px;
        padding-right: 60px;
    }
    .homepage-banner2-h1,
    .homepage-banner3-h1,
    .homepage-banner5-h1,
    .homepage-banner6-h1,
    .homepage-banner7-h1{
        font-size: 24px;
        padding: 0px 6px 0px 6px;
        border-left: 10px solid rgb(27, 110, 244);
    }
    .homepage-banner9-h1 {
        font-size: 24px;
    }
    .homepage-banner2-h1 {
        height: 60px;
    }

    .homepage-banner2-p,
    .homepage-banner6-p,
    .homepage-banner5-p {
        font-size: 1.1rem;
    }
    .homepage-banner3-p {
        padding: 0px 25px;
        font-size: 1.1rem;
    }
    .homepage-banner2-h2 {
        font-size: 20px;
        padding: 10px 0px 25px 0px;
    }

    .homepage-banner2-card2-1 {
        width: 160px;
        height: 220px;
        padding: 10px;
    }

    .homepagehubits-courses-learners-banner2-cards1 {
        width: 220px;
        height: 130px;
        padding: 6px;
    }

    .homepagehubits-courses-learners-banner2-cards-image {
        width: 450px;
        height: 300px;
        margin-left: 10px;
    }
    .homepage-banner6-card {
        gap: 6px;
        padding-top: 30px;
    }

    .homepage-banner6-card1,
    .homepage-banner6-card2 {
        gap: 25px;
    }

    .homepage-banner6-card1-1 {
        inline-size: 210px;
        block-size: 130px;
        padding: 10px;
        font-size: 1rem;
        margin-block-end: 15px;
    }

    .homepage-banner6-card-img {
        inline-size: 450px;
        block-size: 300px;
        margin-top: -5px;
    }
    .homepage-banner8-card1 {
        margin-top: 15px;
    }

    .homepage-banner8-card1-p {
        font-size: 18px;
        width: 85%;
        padding: 0px 6px;
        margin: 0px 0px 20px 0px;
        border-left: 4px solid #007bff;
    }

    .homepage-banner8-card1-btn {
        margin-top: 30px;
        margin-right: 30px;
    }

    .homepage-banner8-card1-botton {
        font-size: 1.1rem;
        padding: 8px 16px;
    }

    .homepage-banner8-card1-botton-faArrowRight {
        margin-left: 10px;
    }

    .homepage-banner8-card2-image {
        width: 420px;
        height: 260px;
    }
}
